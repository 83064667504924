import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import client from '../../api/client';
import webhooksApi from '../../api/webhooks';
import WebhookCard from '../../components/WebhookCard/WebhookCard';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import useApi from '../../hooks/useApi';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import styles from './Webhooks.module.scss';

const Webhooks = () => {
  const getWebhooksApi = useApi(webhooksApi.getWebhooks);
  const getWebhooksUrlApi = useApi(webhooksApi.getWebhooksUrl);
  const [bearer, setBearer] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getWebhooksApi.request();
    getWebhooksUrlApi.request();
    setBearer(false);
  }, [bearer]);

  const webhooks = getWebhooksApi?.data?.apiKeys;
  const publicKey = webhooks?.publicKey;
  const secretKey = webhooks?.secretKey;
  const encryptionKey = webhooks?.encryptionKey;

  const webhooksUrl = getWebhooksUrlApi?.data?.data?.callbackUrl;

  const generateNewKeys = async () => {
    const confitm = window.confirm(
      'New keys will be generated and the old one will become inactive. Continue ?'
    );
    if (!confitm) return;
    try {
      dispatch(openLoader());
      const { data }: any = await client.post(
        `/subsidiary/dashboard/auth/new-key`
      );
      dispatch(
        openToastAndSetContent({
          toastContent:
            data?.message ?? 'New api keys have been generated successfully',
          toastStyles: ToastSuccessStyles,
        })
      );
      getWebhooksApi.request();
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  return (
    <div className={styles.webhooks}>
      {getWebhooksApi?.loading ? (
        <div />
      ) : (
        <>
          <div className={styles.flexContent}>
            <WebhookCard
              setBearer={setBearer}
              post={true}
              urlName="Webhook URL"
              urlContent={webhooksUrl}
            />
            <WebhookCard
              setBearer={setBearer}
              urlName="Public key"
              urlContent={publicKey}
            />
          </div>
          <div className={styles.flexContent}>
            <WebhookCard
              setBearer={setBearer}
              urlName="Secret key"
              urlContent={secretKey}
            />
            <WebhookCard
              setBearer={setBearer}
              urlName="Encryption key"
              urlContent={encryptionKey}
            />
          </div>
          <div className={styles.flexContent}>
            <button className={styles.newKeyBtn} onClick={generateNewKeys}>
              Generate New Keys
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Webhooks;
