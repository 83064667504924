import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './bankdetails.module.scss';

import { useDispatch } from 'react-redux';
import Select from 'react-select';
import banksApi from '../../../api/banks';
import client from '../../../api/client';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import useApi from '../../../hooks/useApi';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};
function BankDetails({ selection, setSelection }: SelectionProp) {
  const dispatch = useDispatch();

  const getBanksApi = useApi(banksApi.getBanks);

  const fetchBanks = async () => {
    getBanksApi.request();
  };

  const [bankDetails, setBankDetails] = useState<any>();

  const fetchBankDetails = async () => {
    dispatch(openLoader());
    try {
      const response: any = await client.get(
        `/subsidiary/dashboard/settlement/bank/get`
      );
      setBankDetails(response?.data?.data);
      setInputs(response?.data?.data[0]);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchBanks();
    fetchBankDetails();
  }, []);

  const [selectedBanks, setSelectedBanks] = React.useState<any>();

  let selectedBanksOptions = getBanksApi?.data?.banks?.map(function (
    bank: any
  ) {
    return {
      value: bank.id,
      label: bank.name,
    };
  });
  interface initTypes {
    accountNumber: string;
    accountName: string;
    bankId: string;
  }

  const initProps: initTypes = {
    accountNumber: bankDetails?.accountNumber,
    accountName: bankDetails?.accountName,
    bankId: bankDetails?.bankId,
  };

  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post('/subsidiary/dashboard/bank/details', {
        ...inputs,
        bank_id: selectedBanks?.value,
      })
      .then((data: any) => {
        dispatch(closeLoader());
        setSelection('Information');
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  return (
    <div className={styles.business}>
      <div className={styles.businessCard} style={{ marginTop: '30px' }}>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="accountNumber"
              inputLabel="Account number"
              inputValue={inputs?.accountNumber}
              inputType="number"
            />
            <div>
              <label className={styles.inputLabel}>Banks</label>
              <Select
                defaultValue={selectedBanks}
                onChange={setSelectedBanks}
                options={selectedBanksOptions}
                // styles={customStyles}
                className={styles.select}
                // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            {/* <OutlineTextInput
              inputName="bank_id"
              inputLabel="Bank Name"
              inputSelect
              inputOption={["One-off", "Product", "Subscription"]}
              handleChange={updateProps}
              inputValue={inputs.bank_id}
            /> */}
            <OutlineTextInput
              handleChange={updateProps}
              inputName="accountName"
              inputLabel="Account name"
              inputValue={inputs?.accountName}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save and Continue
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
