import { serialize } from 'object-to-formdata';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { formClient } from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { numberWithCommas } from '../../../utils/formatNumber';
import OutlineTextInput from '../../input/OutlineTextInput';
import styles from './TreatChargeback.module.scss';

const TreatChargeback = ({ closeModal, data }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    charbackId: string | undefined;
    comment: string | undefined;
    Accepted: string | undefined;
    Proof: any | undefined;
  }
  const ref = useRef<any>(null);

  const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>(
    {
      label: 'Accepted',
      value: 'true',
    }
  );

  const [currenciesOption, setCurrenciesOption] = React.useState<any>([
    {
      label: 'Accepted',
      value: 'true',
    },
    {
      label: 'Decline',
      value: 'false',
    },
  ]);

  const initProps: initTypes = {
    charbackId: undefined,
    comment: undefined,
    Accepted: undefined,
    Proof: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.comment) {
      return true;
    }
  };

  const handleChange = (e: any) => {
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.files,
      };
    });
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const verifyPayout = async () => {
    dispatch(openLoader());
    try {
      const formData = serialize({
        comment: inputs.comment,
        accepted: selectedCurrencyInput?.value == 'true',
        ChargebackId: data?.id,
        // Proof: inputs.Proof
      });
      for (let index = 0; index < inputs.Proof?.length; index++) {
        const element = inputs.Proof[index];
        formData.append('Proof', element);
      }
      const res: any = await formClient.post(
        '/subsidiary/dashboard/chargeback/accept/dispute',
        formData
      );
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      closeModal && closeModal();
    } catch (error: any) {
      const { message } = error?.response?.data || {
        message: 'Something went wrong while trying to process this request',
      };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    verifyPayout();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Treat Chargeback</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <p>
            Please accept or decline chargeback for this transaction{' '}
            {`${data?.currency?.shortName} ${numberWithCommas(data?.amount)}`},
            order reference is {data?.transaction?.transactionReference}
          </p>
          <div className={styles.modalselect}>
            <label className={styles.inputLabel}>
              Accept or Decline Chargeback
            </label>
            <Select
              defaultValue={selectedCurrencyInput}
              onChange={setSelectedCurrencyInput}
              options={currenciesOption}
              className={styles.select}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: '100%',
                }),
              }}
            />
          </div>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="comment"
            inputLabel="Explanation"
            inputValue={inputs.comment}
            multiline={true}
          />
          <OutlineTextInput
            inputType="file"
            multiple
            handleChange={handleChange}
            inputName="Proof"
            inputLabel="Upload proof"
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Submit Request
          </div>
        ) : (
          <div className={styles.notClickable}>Submit Request </div>
        )}
      </div>
    </div>
  );
};

export default TreatChargeback;
