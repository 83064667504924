import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import client from '../../api/client';
import BalanceMenu from '../../components/MenuOption/BalanceMenu/BalanceMenu';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import Filtered from '../../components/filterComponent/Filtered';
import PaginationTable from '../../components/table/pagination-table';
import { ToastErrorStyles } from '../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
  ColumnRollingReserve,
  RollingReserveData,
} from '../../types/TablesTypes/rollingReserve';
import { numberWithCommas } from '../../utils/formatNumber';
import { menuContent } from '../../utils/menuContent';
import styles from './RollingReserve.module.scss';

const RollingReserve = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [dropdown, setDropdown] = useState(false);
  const { currency } = useSelector((state) => state.appSettingsReducer);
  const dispatch = useDispatch();

  function closeModal() {
    setIsOpen(false);
  }

  const fetchRollingReserve = () => {
    dispatch(openLoader());
    client
      .get(`/subsidiary/dashboard/rolling/reserves?currency=${currency}`)
      .then((res: any) => {
        setCustomerDetails(res.data);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
        } else if (error?.response?.data?.message === 'Please login again') {
          sessionStorage.clear();
          window.location.href = '/';
        } else {
        }
      });
  };
  useEffect(() => {
    fetchRollingReserve();
  }, [currency]);

  const dataPaymentLinks = () => {
    const tempArr: RollingReserveData[] = [];
    customerDetails?.data?.items.forEach((balance: any, index: number) => {
      tempArr.push({
        settlementDate: moment(balance?.settlementDate).format(
          'MMMM Do YYYY, h:mm a'
        ),
        dueDate: moment(balance?.settlementDate).format('MMMM Do YYYY, h:mm a'),
        settlementAmount: `${currency} ${numberWithCommas(
          balance?.settlementAmount
        )}`,
        initialAmount: `${currency} ${numberWithCommas(
          balance?.settlementAmount - balance?.withheldAmount
        )}`,
        withheldAmount: `${currency} ${numberWithCommas(
          balance?.withheldAmount
        )}`,
        id: balance?.id,
        status: (
          <StatusView
            status={balance?.status}
            green={'Completed'}
            red={'Due'}
          />
        ),
        balance: `${currency} ${numberWithCommas(balance?.balance)}`,
        action: <BalanceMenu data={balance} />,
      });
    });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Rolling Reserve"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.page?.total}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <Filtered
              menuContent={menuContent}
              dropdown={dropdown}
              setDropdown={setDropdown}
              direct={false}
            />
          </>
        }
      />
      {/* <Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<CreatePaymentLink
					closeModal={closeModal}
					fetchPaymentLink={fetchBalanceHistory}
				/>
			</Modal> */}
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnRollingReserve ? ColumnRollingReserve : []}
          emptyPlaceHolder="You currently do not have any rolling reserve"
          total={customerDetails?.data?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default RollingReserve;
