import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import client from '../../../api/client';
import countriesApi from '../../../api/countries';
import industriesApi from '../../../api/industries';
import { ReactComponent as SaveIcon } from '../../../assets/images/Icons/edit.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import useApi from '../../../hooks/useApi';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './profile.module.scss';

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};

const Profile = ({ selection, setSelection }: SelectionProp) => {
  const dispatch = useDispatch();

  const [profile, setProfile] = useState<any>();

  const fetchProfile = async () => {
    dispatch(openLoader());
    try {
      const response: any = await client.get(
        `/subsidiary/dashboard/kyc/profile/view`
      );
      setProfile(response?.data?.data);
      setInputs(response?.data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  interface initTypes {
    business_name: string;
    email: string;
    description: string;
    bvn?: string;
    Individual?: string;
    business_type?: string;
    industry?: string;
    legal_business_name?: string;
    country?: string;
  }

  const initProps: initTypes = {
    business_name: profile?.legal_business_name,
    email: profile?.email,
    description: profile?.description,
    bvn: profile?.bvn,
    industry: profile?.industry,
    business_type: profile?.business_type,
    legal_business_name: profile?.legal_business_name,
    country: profile?.country,
  };

  const [inputs, setInputs] = useState(initProps);
  const [selectedCountry, setSelectedCountry] = useState<any>();

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post('/subsidiary/dashboard/business/update', {
        ...inputs,
        country: selectedCountry.value,
      })
      .then((data: any) => {
        dispatch(closeLoader());
        setSelection('Bank Details');
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  const getIndustriesApi = useApi(industriesApi.getIndustries);
  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchIndustries = async () => {
    getIndustriesApi.request();
  };

  const fetchCountries = async () => {
    getCountriesApi.request();
  };
  useEffect(() => {
    fetchIndustries();
    fetchCountries();
    fetchProfile();
  }, []);

  let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
    selectedCountry: any
  ) {
    return {
      value: selectedCountry.short_name,
      label: selectedCountry.name,
      currency: selectedCountry.country_code,
    };
  });

  if (getIndustriesApi.loading) {
    return <div className={styles.loading} />;
  }

  const data = getIndustriesApi?.data?.data;

  const industries = data?.map((item: any) => item.name);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <form className={styles.form}>
        <div className={styles.businessCard}>
          <div className={styles.logoText}>Profile</div>
          <div className={styles.businessForm}>
            <OutlineTextInput
              inputName="business_type"
              inputLabel="Business type"
              inputSelect
              inputOption={['Individual', 'Company']}
              handleChange={updateProps}
              inputValue={inputs?.business_type}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="business_email"
              inputLabel="Business email"
              inputValue={inputs?.email}
            />
            <div>
              <label className={styles.inputLabel}>Country</label>
              <Select
                defaultValue={inputs?.country}
                onChange={setSelectedCountry}
                options={selectedCountryOptions}
                // styles={customStyles}
                className={styles.select}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>

            <OutlineTextInput
              handleChange={updateProps}
              inputName="description"
              inputLabel="Description"
              multiline
              inputValue={inputs?.description}
            />
          </div>
        </div>

        <div style={{ marginTop: '24px' }} className={styles.businessCard}>
          <div className={styles.logoText}>Registration</div>
          <div className={styles.businessForm}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="business_name"
              inputLabel="Business name"
              inputValue={inputs?.legal_business_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="legal_business_name"
              inputLabel="Legal business name"
              inputValue={inputs?.legal_business_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="bvn"
              inputLabel="BVN"
              inputValue={inputs?.bvn}
            />
            <OutlineTextInput
              inputName="industry_sector"
              inputLabel="Industry"
              inputValue={inputs?.industry}
              inputSelect
              inputOption={industries}
              handleChange={updateProps}
            />
          </div>
        </div>
      </form>
      <div
        className={styles.uploadButton}
        onClick={(e) => {
          handleSubmit(e);
        }}
      >
        <SaveIcon /> <span>Save</span>
      </div>
    </div>
  );
};

export default Profile;
