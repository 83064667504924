import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import client from '../../../api/client';
import { ReactComponent as AccountIcon } from '../../../assets/images/Icons/account.svg';
import Logo from '../../../assets/images/Logos/paymentgateway.png';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './OnBoardingVerification.module.scss';

const OnBoardingVerification = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const token = params.token;

  interface initTypes {
    Email: string;
    Password?: string;
  }

  const initProps: initTypes = {
    Email: '',
    Password: '',
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const [response, setResponse] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/subsidiary/dashboard/verify/business/email?token=${token}`)
      .then((data: any) => {
        setIsLoading(false);
        dispatch(saveSubsidiaryInfo(data));

        const { status, message, token } = data?.data;
        setResponse(message);
        const { accessToken } = token;
        dispatch(
          openToastAndSetContent({
            toastContent: data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error?.response) {
          setIsLoading(false);
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error?.request) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    handleSubmit();
  }, [token]);

  if (isLoading) {
    return <div />;
  }

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pgw" />
      </div>
      {!!response && (
        <div className={styles.signinForm}>
          <div className={styles.signIn}>{response}</div>
        </div>
      )}
      <div className={styles.newAccount}>
        <div className={styles.noAccount}>Click here to sign in</div>
        <div
          className={styles.create}
          onClick={() => {
            history.push('/');
          }}
        >
          <AccountIcon /> Signin
        </div>
      </div>
    </div>
  );
};

export default OnBoardingVerification;
