import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { Fragment, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import { customStyles } from '../../../constants/modalStyles';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import AddUserModal from '../../ModalContent/AddUserModal/AddUserModal';
import Menus from '../Menus/Menus';
import styles from './UserMenu.module.scss';

type MenuProps = {
  data: any;
  cbfn?: any;
};
const UserMenu = ({ data, cbfn }: MenuProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);

  function closeModalBulk() {
    setIsOpenBulk(false);
    cbfn && cbfn();
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };
  const getUserAction = () => {
    return data?.status?.toLowerCase() == 'new' ||
      data?.status?.toLowerCase() == 'active'
      ? 'Deactivate'
      : 'Activate';
  };

  const toggleUserStatus = async () => {
    dispatch(openLoader());
    try {
      var path = getUserAction().toLowerCase();
      const res: any = await client.post(
        `/subsidiary/dashboard/users/status/${path}`,
        { userId: data.userId }
      );
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      cbfn && cbfn();
    } catch (error: any) {
      const { message } = error?.response?.data ?? { message: error.message };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName={'Update Role'}
                onclick={() => setIsOpenBulk(true)}
              />
              <Menus
                icon={ViewIcon}
                optionName={getUserAction()}
                onclick={() => toggleUserStatus()}
              />
            </div>
          )}
          <Modal
            isOpen={modalIsOpenBulk}
            onRequestClose={closeModalBulk}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <AddUserModal data={data} closeModal={closeModalBulk} />
          </Modal>
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default UserMenu;
