import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

const PublicRoute = ({ children, ...rest }: any) => {
  const access_token = sessionStorage.getItem('accessToken');
  const history = useHistory();

  useEffect(() => {
    if (access_token) {
      window.location.assign('/dashboard');
    }
  }, [children]);

  return <Route {...rest} render={() => (!access_token ? children : <></>)} />;
};

export default PublicRoute;
