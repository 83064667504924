import { Divider, Form, Input, Select } from 'antd';
import { serialize } from 'object-to-formdata';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client, { formClient } from '../../../api/client';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import LogoImg from '../../../assets/iconsOnboarding/image-placeholder.png';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { convertMb } from '../../../utils/convertMb';
import styles from './BusinessInformation.module.scss';

const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

function BusinessInformation() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>({});
  const [businessInfo, setBusinessInfo] = useState<any>({});

  const dispatch = useDispatch();
  const ref = useRef<any>(null);

  const getBusinessInfo = () => {
    dispatch(openLoader());
    client
      .get(`/subsidiary/dashboard/onboarding/business/information/view`)
      .then((data: any) => {
        setBusinessInfo(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    getBusinessInfo();
  }, []);

  const INITIALVALUES = {
    description: '',
    emailaddress: '',
    mobilenumber: '',
    address: '',
    city: '',
    state: '',
    website: '',
    facebook: '',
    instagram: '',
    twitter: '',
    supportEmail: '',
  };

  useEffect(() => {
    const phone = businessInfo?.mobileNumber?.split('+234')[1];
    form.setFieldValue('emailaddress', businessInfo?.supportEmail);
    form.setFieldValue(
      'description',
      businessInfo?.description == 'New Business'
        ? ''
        : businessInfo?.description
    );
    form.setFieldValue('address', businessInfo?.address);
    form.setFieldValue('city', businessInfo?.city);
    form.setFieldValue('state', businessInfo?.state);
    form.setFieldValue('mobilenumber', phone);
    form.setFieldValue('website', businessInfo?.website);
    form.setFieldValue('facebook', businessInfo?.facebook);
    form.setFieldValue('instagram', businessInfo?.instagram);
    form.setFieldValue('twitter', businessInfo?.twitter);
  }, [businessInfo]);

  const updatedHandler = (values: any) => {
    dispatch(openLoader());
    const data = {
      ...values,
      mobilenumber: `+234${values.mobilenumber}`,
      ...files,
    };
    const formData = serialize(data);
    formClient
      .post(`/subsidiary/dashboard/onboarding/business/information`, formData)
      .then(async (res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        await getOnboardingPercentagesAction(dispatch);
        history.push('/dashboard/getStarted/onboarding');
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const handleChange = (e: any) => {
    setFiles((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.files[0],
      };
    });
  };

  const validateUrl = (userInput: any) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res != null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div
          onClick={() => history.push('/dashboard/getStarted/onboarding')}
          className={styles.wrapperInnerLeft}
        >
          <img src={arrow} alt="" />
          <p>Get started</p>
        </div>
        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>Business Information</h2>
          <p className={styles.wrapperp}>
            We need this information to give us an idea of the kind of business
            you run, the type of product/services you will need to maximize this
            portal and how we cangenerally serve you better.
          </p>

          <Form
            form={form}
            initialValues={INITIALVALUES}
            layout="vertical"
            name="nest-messages"
            onFinish={updatedHandler}
            validateMessages={validateMessages}
          >
            <div className={styles.inputwrapper}>
              <Form.Item
                style={{ flex: 1 }}
                name="description"
                label={'What do you do at ' + businessInfo?.name + '?'}
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder={businessInfo.description}
                  style={{ borderRadius: '7px' }}
                  className={styles.inputText}
                  rows={3}
                  maxLength={250}
                />
              </Form.Item>

              <h3 className={styles.formH3}>
                For example, Payinvert limited sells different types of shoes,
                slippers, sandals and bags on our instagram and website.
              </h3>

              <Form.Item
                style={{ flex: 1 }}
                name="emailaddress"
                label="Business email"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                label="Business phone number"
                name="mobilenumber"
                rules={[
                  {
                    required: true,
                  },
                  { min: 10 },
                  { max: 10 },
                  {
                    validator: (_, value) =>
                      /[0-9]/.test(value)
                        ? Promise.resolve()
                        : Promise.reject('mobile number should contain number'),
                  },
                ]}
              >
                <Input
                  style={{
                    borderRadius: '7px',
                    width: '100%',
                    paddingTop: '10px',
                  }}
                  addonBefore="+234"
                  className={styles.input}
                  placeholder="8107061526"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                label="Business address"
                name="address"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>

              <div className={styles.flexInput}>
                <Form.Item
                  style={{ flex: 1, marginRight: '16px' }}
                  label="City"
                  name="city"
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                  />
                </Form.Item>

                <Form.Item
                  style={{ flex: 1 }}
                  label="State"
                  name="state"
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.inputwrapper}>
              <div style={{ flex: 1 }}>
                <h3 className={styles.formH3}>Business logo (Optional)</h3>
                <div className={styles.logowrapper}>
                  <label
                    style={{ cursor: 'pointer' }}
                    onClick={() => ref.current.click()}
                  >
                    {files?.subsidiarylogo ? (
                      <img
                        src={URL.createObjectURL(files?.subsidiarylogo)}
                        className={styles.preview}
                        alt="subsidiarylogo"
                      />
                    ) : (
                      <img
                        src={
                          businessInfo.logo !== '' ? businessInfo.logo : LogoImg
                        }
                        alt=""
                        className={styles.imagelogo}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    name="subsidiarylogo"
                    id="subsidiarylogo"
                    accept="image/png,image/jpg,image/jpeg"
                    onChange={handleChange}
                    ref={ref}
                    hidden
                  />
                  {files?.subsidiarylogo ? (
                    <div>
                      {convertMb(files?.subsidiarylogo?.size) > 1 ? (
                        <p style={{ color: 'red' }}>
                          Document should be 1mb or less
                        </p>
                      ) : (
                        `${convertMb(files?.subsidiarylogo?.size)}`
                      )}
                    </div>
                  ) : (
                    <p>
                      We use your logo on payment pages to increase the
                      credibility for your customers. Please upload a square
                      image of your logo not larger than 1mb.
                    </p>
                  )}
                </div>
              </div>
              <Form.Item
                style={{ flex: 1 }}
                name="website"
                label="Website"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validateUrl(value)
                        ? Promise.resolve()
                        : Promise.reject('Please enter a valid url'),
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="facebook"
                label="Facebook username (optional)"
                rules={[{ required: false }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="instagram"
                label="Instagram handle (optional)"
                rules={[{ required: false }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>{' '}
              <Form.Item
                style={{ flex: 1 }}
                name="twitter"
                label="Twitter handle (optional)"
                rules={[{ required: false }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                />
              </Form.Item>
              <Divider />
              <div className={styles.buttonflex}>
                <button className={styles.cancel}>Back</button>
                <Form.Item shouldUpdate>
                  {() => (
                    <button type="submit" className={styles.submitHandler}>
                      Save
                    </button>
                  )}
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default BusinessInformation;
