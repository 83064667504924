import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import OutlineTextInput from '../../input/OutlineTextInput';
import styles from './Createpaymentlink.module.scss';

const CreatePaymentLink = ({ closeModal, fetchPaymentLink }: any) => {
  const dispatch = useDispatch();

  const [paymentTypes, setPaymentTypes] = React.useState<any>([]);
  const [currencies, setCurrencies] = React.useState<any>([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();
  const [selectedAuthOption, setSelectedAuthOption] = React.useState<any>();
  interface initTypes {
    name: string;
    description: string;
    amount: number | undefined;
    limit?: number | undefined;
    currency: string | undefined;
    authOption: string | undefined;
  }

  const initProps: initTypes = {
    name: '',
    description: '',
    amount: undefined,
    limit: undefined,
    currency: undefined,
    authOption: 'AUTH',
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name && selectedPaymentType?.value && selectedCurrency?.value)
      return true;
    return false;
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const fetchPaymentLinkTypes = async () => {
    try {
      const data: any = await client.get(
        '/subsidiary/dashboard/payment/link/types'
      );
      setPaymentTypes(data?.data?.paymentLinkTypes);
    } catch (error: any) {
      const { message } = error?.response?.data ?? {};
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response: any = await client.get(
        '/subsidiary/dashboard/service/currencies'
      );
      setCurrencies(response?.data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  let selectedPaymentTypeOptions = paymentTypes.map(
    (selectedPaymentType: any) => {
      return {
        value: selectedPaymentType.code,
        label: selectedPaymentType.paymentLinkName,
      };
    }
  );

  let authOptions = [
    {
      value: 'AUTH',
      label: 'Authorize',
    },
    {
      value: 'NOAUTH',
      label: 'No Authorization',
    },
  ];

  let currencyOptions = currencies.map(function (selectedcurrency: any) {
    return {
      value: selectedcurrency.shortName,
      label: selectedcurrency.name,
    };
  });
  const createPaymentLink = async () => {
    try {
      dispatch(openLoader());
      const data: any = await client.post(
        '/subsidiary/dashboard/payment/link/new',
        {
          ...inputs,
          paymentType: selectedPaymentType.value,
          authOption: selectedAuthOption?.value,
          currency: selectedCurrency.value,
        }
      );

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data ?? { message: error.message };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    fetchPaymentLinkTypes();
    fetchCurrencies();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New payment link</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Link name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />

          <Select
            defaultValue={selectedCurrency}
            onChange={setSelectedCurrency}
            options={currencyOptions}
            placeholder="Currency"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '300px',
              }),
            }}
          />

          <Select
            defaultValue={selectedPaymentType}
            onChange={setSelectedPaymentType}
            options={selectedPaymentTypeOptions}
            placeholder="Payment Type"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '300px',
              }),
            }}
          />

          {/* 
          <Select
            defaultValue={selectedPaymentType}
            onChange={setSelectedAuthOption}
            options={authOptions}
            placeholder="Auth Option"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: "300px",
              }),
            }}
          /> */}

          <OutlineTextInput
            notRequired={true}
            inputType="number"
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Amount (Optional)"
            inputValue={inputs.amount}
          />

          <OutlineTextInput
            notRequired={true}
            handleChange={updateProps}
            inputName="limit"
            inputType="number"
            inputLabel="Limit (Optional)"
            inputValue={inputs.limit}
          />
        </form>
      </div>

      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new link
          </div>
        ) : (
          <div className={styles.notClickable}>Create new link</div>
        )}
      </div>
    </div>
  );
};

export default CreatePaymentLink;
