import { GETSTARTEDSTATE } from '../constants';
import { closeLoader, openLoader } from '../loader/loaderActions';
import { saveOnboardLevelInfo } from '../onboardlevel/onboardLevelAction';
import api from './../../../api';

export const signInAction = async (payload: any, cb: Function) => {
  try {
    var res: any = await api.signIn(payload);
    var data = res?.data ?? {};
    if (data?.token?.accessToken)
      sessionStorage.setItem('accessToken', data?.token?.accessToken);
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  const getStartedState = {};
  return {
    type: GETSTARTEDSTATE,
    getStartedState,
  };
};

export const signInCompleteAction = async (payload: any, cb: Function) => {
  try {
    var res: any = await api.signInComplete(payload);
    var data = res?.data ?? {};
    if (data?.token?.accessToken)
      sessionStorage.setItem('accessToken', data?.token?.accessToken);
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  const getStartedState = {};
  return {
    type: GETSTARTEDSTATE,
    getStartedState,
  };
};

export const getProfileDetailsAction = async (cb: Function) => {
  let profileinfo = {};
  try {
    var res: any = await api.getProfileDetails();
    cb(res.data);
    profileinfo = res.data;
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  return {
    type: GETSTARTEDSTATE,
    profileinfo,
  };
};

export const createAccountAction = async (payload: any, cb: Function) => {
  try {
    var res: any = await api.createAccount(payload);
    var res1: any = await api.sendotp({ emailAddress: payload.emailAddress });
    var data = res?.data?.data ?? {};
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  const getStartedState = {};
  return {
    type: GETSTARTEDSTATE,
    getStartedState,
  };
};

export const validateEmailAction = async (payload: any, cb: Function) => {
  let logInUser2FA = {};
  try {
    var res: any = await api.validateEmail(payload);
    var data = res?.data?.data ?? {};
    logInUser2FA = data;
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  return {
    type: GETSTARTEDSTATE,
    logInUser2FA,
  };
};

export const comple2FASetupAction = async (payload: any, cb: Function) => {
  let logInUser2FA = {};
  try {
    var res: any = await api.complete2fASetup(payload);
    var data = res?.data?.data ?? {};
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  return {
    type: GETSTARTEDSTATE,
    logInUser2FA,
  };
};

export const addNewOrganizationAction = async (payload: any, cb: Function) => {
  // var res = await api.createAccount(payload)
  const res = {
    code: '00',
    success: true,
    message: 'Organization added',
    data: {
      id: '4899',
      name: payload.name,
    },
  };
  if (res.code != '00') {
    cb(null, { message: res.message });
  } else {
    cb(res);
    return {
      type: GETSTARTEDSTATE,
      newOrganization: res.data,
    };
  }
};

export const completeOnboardingStepsAction = async (cb: Function) => {
  try {
    var res = await api.completeOnboardingSteps({});
    var data: any = res?.data ?? {};
    if (data.statusCode != '00') throw new Error(data.message);
    cb(data);
  } catch (error: any) {
    var err = error?.response?.data;
    cb(null, { message: err.message ?? err.title });
  }
  return {
    type: '',
  };
};

export const getOnboardingPercentagesAction = async (dispatch: Function) => {
  try {
    dispatch(openLoader());
    const data: any = await api.getOnboardingPercentages();
    dispatch(saveOnboardLevelInfo(data?.data));
  } catch (error: any) {
    const message = error?.response?.data?.message;
    // dispatch(
    // 	openToastAndSetContent({
    // 		toastContent: message,
    // 		toastStyles: ToastErrorStyles,
    // 	})
    // );
  } finally {
    dispatch(closeLoader());
  }
};
