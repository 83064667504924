import { Divider, Form, Input, Modal, Select } from 'antd';
import { serialize } from 'object-to-formdata';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client, { formClient } from '../../../api/client';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import EditIcon from '../../../assets/iconsOnboarding/u_pen.svg';
import RemoveIcon from '../../../assets/iconsOnboarding/u_x.svg';
import { ReactComponent as CloseIcon } from '../../../assets/iconsOnboarding/u_xxx.svg';
import PlusIcon from '../../../assets/iconsOnboarding/uploadplus.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { convertMb } from '../../../utils/convertMb';
import AddStakeholder from './AddStakeholder';
import styles from './BusinessRegistration.module.scss';
import EditStakeholder from './EditStakeholder';

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */
interface idType {
  id: number;
  name: string;
  countryId: number;
  bankCode: string;
  isMicrofinance: null | string;
  isMortgage: null | string;
}

interface stakeType {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  mobileNumber: string;
  bvn: string;
}
function BusinessRegistration() {
  const history = useHistory();
  const [businessClass, setBusinessClass] = useState<idType[]>([]);
  const [files, setFiles] = useState<any>({});
  const [info, setInfo] = useState<any>();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const ref = useRef<any>(null);
  const refparticular = useRef<any>(null);
  const refshares = useRef<any>(null);
  const refpoa = useRef<any>(null);
  const refdirector1 = useRef<any>(null);
  const reflicense = useRef<any>(null);
  const refmemo = useRef<any>(null);

  const [stakeHolder, setStakeHolder] = useState<stakeType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [editStake, setEditStake] = useState<stakeType>();

  const getIdType = () => {
    dispatch(closeLoader());
    client
      .get(`/subsidiary/dashboard/business/classes`)
      .then((data: any) => {
        setBusinessClass(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    getIdType();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalEdit = () => {
    setIsModalEditOpen(true);
  };

  const handleEditOk = () => {
    setIsModalEditOpen(false);
  };

  const handleEditCancel = () => {
    setIsModalEditOpen(false);
  };
  const INITIALVALUES = {
    businessclassid: '',
    taxidnumber: '',
  };

  const isDocumentsOnFile = (documents: any) => {
    const _info = documents?.registrationDocument;
    const _infoOthers: any[] = documents?.otherDocuments ?? [];
    const poa = _infoOthers.find(
      (x) => x.documentName == 'ProofOfAddress'
    )?.value;
    const directorId = _infoOthers.find(
      (x) => x.documentName == 'DirectorIdentification'
    )?.value;
    // const poa = _infoOthers.find(x => x.documentName =="DirectorIdentification");
    if (_info == null) return false;
    if (
      _info.certOfIncorporation &&
      _info.memoOfAssociation &&
      _info.taxIdNumber &&
      _info.businessClassId > 0 &&
      directorId &&
      poa &&
      stakeHolder.length > 0
    ) {
      // if ((_info.certOfIncorporation, _info.memoOfAssociation, _info.particularsofdirectors, _info.proofofaddress, _info.directorsidentification, stakeHolder.length > 0)) {
      return true;
    } else {
      return false;
    }
    return false;
  };

  const isFileFilled = () => {
    if (
      files.certofincorporation &&
      files.memoofassociation &&
      files.proofofaddress &&
      files.directorsidentification &&
      stakeHolder.length > 0
    )
      return true;
    return false;
  };
  const validateFormInput = () => {
    if (form.isFieldsTouched(true) || isFileFilled()) return false;
    if (isDocumentsOnFile(info)) return false;
    // var errors = form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    return true;
  };

  const handleChange = (e: any) => {
    setFiles((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.files[0],
      };
    });
  };

  const updatedHandler = (values: any) => {
    dispatch(openLoader());
    const data = { ...values, ...files };
    const formData = serialize(data);
    formClient
      .post(
        `/subsidiary/dashboard/onboarding/business/registration/add-or-update`,
        formData
      )
      .then(async (res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        await getOnboardingPercentagesAction(dispatch);
        history.push('/dashboard/getStarted/onboarding');
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data ?? {
          message: 'Files sizes may be too large',
        };
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const getStake = () => {
    client
      .get(`/subsidiary/dashboard/onboarding/stakeholder/view`)
      .then((data: any) => {
        setStakeHolder(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const getInfo = () => {
    dispatch(openLoader());
    client
      .get(`/subsidiary/dashboard/onboarding/business/registration/view`)
      .then((data: any) => {
        setInfo(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data ?? {
          message: 'Unable to get business registration documents',
        };
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    getStake();
    getInfo();
  }, []);

  const editHandler = (item: stakeType) => {
    setEditStake(item);
    showModalEdit();
  };

  const removeHandler = (id: number) => {
    client
      .delete(`/subsidiary/dashboard/onboarding/stakeholder/delete/${id}`)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        getStake();
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  useEffect(() => {
    // const phone = businessInfo?.mobileNumber?.split("+234")[1];
    form.setFieldValue(
      'businessclassid',
      info?.registrationDocument?.businessClassId
    );
    form.setFieldValue('taxidnumber', info?.registrationDocument?.taxIdNumber);
    form.setFieldValue(
      'registrationNumber',
      info?.registrationDocument?.registrationNumber
    );
    form.setFieldValue(
      'expectedMonthlyTransactionVolumn',
      info?.registrationDocument?.expectedMonthlyTransactionVolumn
    );
    // form.setFieldValue("address", businessInfo?.address);
    // form.setFieldValue("city", businessInfo?.city);
    // form.setFieldValue("state", businessInfo?.state);
    // form.setFieldValue("mobilenumber", phone);
    // form.setFieldValue("website", businessInfo?.website);
    // form.setFieldValue("facebook", businessInfo?.facebook);
    // form.setFieldValue("instagram", businessInfo?.instagram);
    // form.setFieldValue("twitter", businessInfo?.twitter);
  }, [info]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div
          onClick={() => history.push('/dashboard/getStarted/onboarding')}
          className={styles.wrapperInnerLeft}
        >
          <img src={arrow} alt="" />
          <p>Get started</p>
        </div>
        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>Business Registration</h2>
          <p className={styles.wrapperp}>
            We need this information to give us an idea of the kind of business
            you run, the type of product/services you will need to maximize this
            portal and how we cangenerally serve you better.
          </p>
          <Form
            form={form}
            initialValues={INITIALVALUES}
            layout="vertical"
            name="nest-messages"
            onFinish={updatedHandler}
            validateMessages={validateMessages}
          >
            <div className={styles.inputwrapper}>
              <Form.Item
                style={{ flex: 1 }}
                name="businessclassid"
                label="Business class"
                className="selectOptions"
                rules={[{ required: true }]}
              >
                <Select placeholder="Business class" allowClear>
                  {businessClass.length > 0 &&
                    businessClass?.map(({ name, id }, i) => (
                      <Option key={i} value={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="registrationNumber"
                label="Registration Number"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  maxLength={20}
                  type="text"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="expectedMonthlyTransactionVolumn"
                label="Expected Monthly Transaction Volume"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  maxLength={20}
                  type="number"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="taxidnumber"
                label="Tax Identification Number"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  maxLength={20}
                  type="text"
                />
              </Form.Item>
            </div>
            <div className={styles.inputwrapper}>
              <h3 className={styles.formH3}>
                All documents must be in either .jpeg, .jpg, .png pr .pdf
                format, with max size of 1mb
              </h3>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => ref.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: '20px' }}>
                      Certificate of incorporation*
                    </h1>
                    {files?.certofincorporation ? (
                      <div>
                        {convertMb(files?.certofincorporation?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.certofincorporation?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="certofincorporation"
                  id="certofincorporation"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={ref}
                  hidden
                />
                {files && files.certofincorporation && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.certofincorporation)}
                      className={styles.preview}
                      alt="certofincorporation"
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => refmemo.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: '20px' }}>
                      Memorandum and articles of association*
                    </h1>
                    {files?.memoofassociation ? (
                      <div>
                        {convertMb(files?.memoofassociation?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.memoofassociation?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="memoofassociation"
                  id="memoofassociation"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refmemo}
                  hidden
                />
                {files && files.memoofassociation && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.memoofassociation)}
                      className={styles.preview}
                      alt="memoofassociation"
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <h6 className={styles.labelHeader}>
                  Particulars of Directors (Optional)
                </h6>

                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => refparticular.current.click()}
                >
                  <div className={styles.uploadBox}>
                    {files?.particularsofdirectors ? (
                      <div>
                        {convertMb(files?.particularsofdirectors?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.particularsofdirectors?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>
                        <span className={styles.uploadspan}>
                          <img src={PlusIcon} alt="" />
                        </span>
                        Select file
                      </p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="particularsofdirectors"
                  id="particularsofdirectors"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refparticular}
                  hidden
                />
                {files && files.particularsofdirectors && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.particularsofdirectors)}
                      className={styles.preview}
                      alt="particularsofdirectors"
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <h6 className={styles.labelHeader}>
                  Statement of return on allotment of shares (optional)
                </h6>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => refshares.current.click()}
                >
                  <div className={styles.uploadBox}>
                    {files?.statementofshares ? (
                      <div>
                        {convertMb(files?.statementofshares?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.statementofshares?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>
                        <span className={styles.uploadspan}>
                          <img src={PlusIcon} alt="" />
                        </span>
                        Select file
                      </p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="statementofshares"
                  id="statementofshares"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refshares}
                  hidden
                />
                {files && files.statementofshares && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.statementofshares)}
                      className={styles.preview}
                      alt="statementofshares"
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => refpoa.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: '20px' }}>Proof of address*</h1>
                    {files?.proofofaddress ? (
                      <div>
                        {convertMb(files?.proofofaddress?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.proofofaddress?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="proofofaddress"
                  id="proofofaddress"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refpoa}
                  hidden
                />
                {files && files.proofofaddress && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.proofofaddress)}
                      className={styles.preview}
                      alt="proofofaddress"
                    />
                  </div>
                )}
              </div>
              <h3 className={styles.formH3}>
                Primary identification documents of all shareholders with 5%
                ownership and above (Merge all documents into one)
              </h3>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => refdirector1.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: '20px' }}>Director 1*</h1>
                    {files?.directorsidentification ? (
                      <div>
                        {convertMb(files?.directorsidentification?.size) > 5 ? (
                          <p style={{ color: 'red' }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          `${convertMb(files?.directorsidentification?.size)}`
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="directorsidentification"
                  id="directorsidentification"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refdirector1}
                  hidden
                />
                {files && files.directorsidentification && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.directorsidentification)}
                      className={styles.preview}
                      alt="directorsidentification"
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: '30px' }}>
                <h6 className={styles.labelHeader}>
                  Operating license (optional)
                </h6>
                <label
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => reflicense.current.click()}
                >
                  <div className={styles.uploadBox}>
                    <p>
                      <span className={styles.uploadspan}>
                        <img src={PlusIcon} alt="" />
                      </span>
                      Select file{' '}
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="operatinglicense"
                  id="operatinglicense"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={reflicense}
                  hidden
                />
                {files && files.operatinglicense && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.operatinglicense)}
                      className={styles.preview}
                      alt="operatinglicense"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.inputwrapper}>
              <p className={styles.numberP}>{stakeHolder?.length} added</p>
              <div className={styles.stakeholderbox}>
                {stakeHolder?.map((item) => (
                  <div className={styles.stakeholdersingle} key={item.id}>
                    <p>
                      {item.firstName} - {item.lastName}
                    </p>
                    <div className={styles.imageflex}>
                      <img
                        onClick={() => editHandler(item)}
                        src={EditIcon}
                        alt=""
                      />
                      <Divider type="vertical" />
                      <img
                        onClick={() => removeHandler(item.id)}
                        src={RemoveIcon}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
              <p onClick={showModal} className={styles.stakeholderclick}>
                Add Shareholders
              </p>
              <Divider />
              <div className={styles.buttonflex}>
                <div
                  onClick={() =>
                    history.push('/dashboard/getStarted/onboarding')
                  }
                  className={styles.cancel}
                >
                  Back
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <button
                      type="submit"
                      disabled={validateFormInput()}
                      className={styles.submitHandler}
                    >
                      Save
                    </button>
                  )}
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        title="Add Shareholders with 5% and above"
        open={isModalOpen}
        closeIcon={<CloseIcon />}
        closable={false}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <AddStakeholder getStake={getStake} handleCancel={handleCancel} />
      </Modal>

      {/* EDIT */}

      <Modal
        title="Edit Shareholders"
        open={isModalEditOpen}
        closeIcon={<CloseIcon />}
        closable={false}
        footer={null}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        <EditStakeholder
          handleEditCancel={handleEditCancel}
          getStake={getStake}
          editStake={editStake}
        />
      </Modal>
    </div>
  );
}

export default BusinessRegistration;
