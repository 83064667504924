import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import React, { Fragment, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formClient } from '../../../api/client';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Icons/delete-fee.svg';
import ImagePlaceholder from '../../../assets/images/Icons/image-placeholder.png';
import { ReactComponent as MarkIcon } from '../../../assets/images/Icons/marker.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Icons/u_copy.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/Icons/upload-image.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import DeliveryFee from '../../../components/ModalContent/DeliveryFee/DeliveryFee';
import { customStyles } from '../../../constants/modalStyles';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
  inputStyle,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './Newproduct.module.scss';

type itemProps = {
  name: string;
  fee: number;
};
const NewProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  interface initTypes {
    name: any;
    description?: any;
    price?: number | undefined;
    dealprice?: number | undefined;
    ondeal?: boolean;
    containsphysicalgoods?: boolean;
    deliveryaddressrequired?: boolean;
    deliverynoterequired?: boolean;
    stock?: any;
    url?: any;
    fees?: any;
    images?: any;
    limited: string;
    unlimited: string;
  }

  const initProps: initTypes = {
    name: '',
    description: '',
    price: 0,
    dealprice: 0,
    ondeal: false,
    containsphysicalgoods: false,
    deliveryaddressrequired: false,
    deliverynoterequired: false,
    stock: '',
    url: '',
    fees: '',
    images: '',
    limited: 'limited',
    unlimited: 'unlimited',
  };

  const [physicalGoods, setPhysicalGoods] = React.useState<any>(false);
  const [deliveryAddress, setDeliveryAddress] = React.useState<any>(false);
  const [deliveryNote, setDeliveryNote] = React.useState<any>(false);
  const [onSale, setOnSale] = React.useState<any>(false);
  const [feeEntry, setFeeEntry] = React.useState<any>(false);
  const [copy, setCopy] = React.useState<any>(false);
  const [inputs, setInputs] = React.useState(initProps);
  const [images, setImages] = React.useState<any>([]);
  const [imageURLs, setImagesURLs] = React.useState<any>([]);

  const [imagesPush, setImagesPush] = React.useState<any>([]);

  const [items, setItems] = useState<itemProps[]>([{ name: '', fee: 0 }]);

  let handleChangeItems = (index: any, e: any) => {
    let newItems = [...items];
    if (e.target.name.startsWith('fee')) {
      newItems[index].fee = Number(e.target.value);
    } else {
      newItems[index].name = e.target.value;
    }
    setItems(newItems);
  };

  let addFormFields = () => {
    setItems([...items, { fee: 0, name: '' }]);
  };

  let removeFormFields = (i: any) => {
    let newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
  };

  let removeImgFields = (identifier: any, i: any) => {
    let newImages = images.filter(
      (item: any) => item.name !== identifier.image.name
    );
    let newItems = [...imageURLs];
    newItems.splice(i, 1);
    setImages(newImages);

    setImagesURLs(newItems);
  };

  const onSaleHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnSale(event.target.checked);
  };
  const deliveryAddressHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeliveryAddress(event.target.checked);
  };
  const deliveryNoteHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryNote(event.target.checked);
  };
  const handleChangePhysical = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhysicalGoods(event.target.checked);
  };

  const copyHandler = () => {
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const imageUpload = (e: any) => {
    setImages([...images, ...e.target.files]);
    e.target.value = null;
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls: any[] = [];
    const imageArray: any[] = [];
    images.forEach((image: any) => {
      imageArray.push(image);
      newImageUrls.push({ url: URL.createObjectURL(image), image });
    });
    setImagesPush(imageArray);
    setImagesURLs(newImageUrls);
  }, [images]);

  useEffect(() => {}, [imageURLs, images]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const payload = {
    dealprice: onSale,
    containsphysicalgoods: physicalGoods,
    deliveryaddressrequired: deliveryAddress,
    deliverynoterequired: deliveryNote,
    images: images,
  };

  let formData: any = new FormData();

  formData.append('name', inputs?.name); //append the values with key, value pair
  formData.append('description', inputs?.description);
  formData.append('price', inputs?.price);
  formData.append('dealprice', inputs.dealprice);
  formData.append('url', inputs?.url);
  formData.append('containsphysicalgoods', payload.containsphysicalgoods);
  formData.append('deliveryaddressrequired', payload.deliveryaddressrequired);
  formData.append('deliverynoterequired', payload.deliverynoterequired);
  imagesPush?.map(function (images: any) {
    return formData.append('images', images);
  });
  !!items[0]?.name && items[0]?.fee && formData.append('fees', items);
  !!inputs?.stock
    ? formData.append('stock', inputs?.stock)
    : formData.append('stock', 0);

  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    stock: any
  ) => {
    if (stock) {
      setSelectedValue(inputs?.limited);
    } else {
      setSelectedValue(event.target.value);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    formClient
      .post('/subsidiary/dashboard/product/create', formData)
      .then((data: any) => {
        history.push('/dashboard/business/products');
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        if (error?.response?.data?.message === 'Please login again') {
          sessionStorage.clear();
          window.location.href = '/';
        } else {
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        }
      });
  };

  const disableFunction = () => {
    if (
      inputs.name !== '' &&
      inputs.description !== '' &&
      images.length > 0 &&
      inputs.stock > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={styles.preferencesBody}>
      <div className={styles.newProduct}>
        <div className={styles.transactionsHeader}>
          <div className={styles.text}> New product</div>
        </div>
        <button
          disabled={disableFunction()}
          className={styles.save}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          <MarkIcon />
          &nbsp; Save
        </button>
      </div>
      <div
        className={styles.flexwrapper}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: '20px',
          width: '100%',
        }}
      >
        <div style={{ width: '475px' }}>
          <div className={styles.businessCard} style={{ marginTop: '10px' }}>
            <div className={styles.logoText}>Product information</div>

            <div className={styles.businessForm}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="name"
                  inputLabel="Product name"
                  inputValue={inputs.name}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="description"
                  inputLabel="Description"
                  rows={2}
                  multiline={true}
                  inputValue={inputs.description}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="price"
                  inputLabel="Price"
                  inputValue={inputs.price}
                />

                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="stock"
                  inputLabel="Stock"
                  inputValue={inputs.stock}
                />
                <div className={styles.logoText}>Quantity</div>

                <div className={styles.radioSelection}>
                  <Radio
                    checked={selectedValue === inputs?.limited}
                    onChange={(event) => {
                      handleChange(event, inputs?.stock);
                    }}
                    value={inputs?.limited}
                    name="limited"
                    inputProps={{ 'aria-label': 'limited' }}
                    size="small"
                  />
                  <div className={styles.radioLabel}>Limited</div>
                </div>
                <div className={styles.radioSelection}>
                  <Radio
                    checked={selectedValue === inputs?.unlimited}
                    onChange={(event) => {
                      handleChange(event, inputs?.stock);
                    }}
                    value={inputs?.unlimited}
                    name="unlimited"
                    inputProps={{ 'aria-label': 'Unlimited' }}
                    size="small"
                  />
                  <div className={styles.radioLabel}>Unlimited</div>
                </div>
              </form>
            </div>
          </div>

          <div className={styles.paymentMethods}>
            <div className={styles.accountInfo}>Delivery</div>

            <div className={styles.paymentList}>
              <div className={styles.checkboxContent}>
                <Checkbox
                  checked={physicalGoods}
                  onChange={handleChangePhysical}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <div className={styles.checkboxLabel}>
                  This product contains one or more physical{' '}
                </div>
              </div>
              <div className={styles.checkboxContent}>
                <Checkbox
                  {...label}
                  checked={deliveryAddress}
                  onChange={deliveryAddressHandler}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <div className={styles.checkboxLabel}>
                  Require delivery address
                </div>
              </div>
              <div className={styles.checkboxContent}>
                <Checkbox
                  checked={deliveryNote}
                  onChange={deliveryNoteHandler}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <div className={styles.checkboxLabel}>
                  Require delivery note
                </div>
              </div>
              {!!feeEntry &&
                items.map((item: any, index) => {
                  return (
                    <div className={styles.feeEntry} key={index}>
                      <div className={styles.feeEntryName}>
                        {item.name} {'-'} NGN{item.fee}
                      </div>
                      <div className={styles.feeEntryPrice}>
                        <DeleteIcon
                          onClick={() => removeFormFields(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  );
                })}
              <div
                className={styles.deliveryButton}
                onClick={() => setIsOpen(true)}
              >
                <MarkIcon /> <span>Add delivery fee</span>
              </div>

              {/* <div>
                <div className={styles.newItem} onClick={() => addFormFields()}>
                  Add another item
                </div>
              </div> */}
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
              >
                <DeliveryFee
                  closeModal={closeModal}
                  onClick={() => {
                    closeModal();
                    setFeeEntry(true);
                  }}
                  fee={items[0]}
                  ModalBody={
                    <div className={styles.businessFormModal}>
                      {items.map((element, index) => (
                        <Fragment>
                          <div className="form-inline" key={index}>
                            <div className={styles.businessType}>
                              <div style={{ width: '100%', marginTop: '27px' }}>
                                <label className={styles.inputLabel}>
                                  Fee type
                                </label>
                                <input
                                  type="text"
                                  name={`name${index}`}
                                  value={element.name || ''}
                                  id={`name${index}`}
                                  onChange={(e) => handleChangeItems(index, e)}
                                  style={inputStyle}
                                />
                              </div>
                              <div style={{ marginTop: '27px' }}>
                                <label className={styles.inputLabel}>
                                  Amount
                                </label>
                                <input
                                  type="number"
                                  name={`fee${index}`}
                                  value={element.fee || ''}
                                  onChange={(e) => handleChangeItems(index, e)}
                                  style={inputStyle}
                                />
                              </div>
                            </div>

                            {index ? (
                              <div
                                className={styles.removeButton}
                                onClick={() => removeFormFields(index)}
                              >
                                Remove item
                              </div>
                            ) : null}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  }
                ></DeliveryFee>
              </Modal>
            </div>
          </div>

          <div className={styles.paymentMethods}>
            <div className={styles.accountInfo}>Sale</div>
            <div className={styles.paymentList}>
              <div className={styles.checkboxContentFa}>
                <Checkbox
                  checked={onSale}
                  onChange={onSaleHandleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <div className={styles.checkboxLabel}>
                  This product is on sale
                </div>
              </div>
            </div>
            <div className={styles.businessForm}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="dealprice"
                  inputLabel="Sale price"
                  inputValue={inputs.dealprice}
                />
              </form>
            </div>
          </div>

          <div className={styles.paymentMethods}>
            <div className={styles.accountInfo}>Product URL</div>
            <div className={styles.businessForm}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="url"
                  inputLabel="Product URL"
                  inputValue={inputs.url}
                />
              </form>
            </div>
            {copy ? <span style={{ color: 'green' }}>Copied.</span> : null}
            <CopyToClipboard text="hey" onCopy={copyHandler}>
              <div className={styles.deliveryButton}>
                <CopyIcon /> <span>Copy</span>
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <div style={{ width: '475px', marginTop: '10px' }}>
          <div className={styles.businessCard}>
            <div className={styles.logoText}>Product image</div>
            <div className={styles.accountEarnings}>
              Add up to 6 high quality product images
            </div>
            <div className={styles.imagePlaceholder}>
              {imageURLs.length ? (
                imageURLs.map((image: any, index: number) => (
                  <img
                    key={index}
                    src={image.url}
                    alt="logo-placeholder"
                    width={80}
                    height={80}
                  />
                ))
              ) : (
                <img src={ImagePlaceholder} alt="logo-placeholder" />
              )}
            </div>

            <div className={styles.uploadButton}>
              <UploadIcon />

              <input
                disabled={imageURLs.length >= 6 ? true : false}
                type="file"
                multiple
                name="images"
                accept="image/*"
                onChange={(e: any) => {
                  imageUpload(e);
                }}
                //   hidden
              />
              {/* <span>Upload Image</span> */}
            </div>

            <div style={{ marginTop: '5px' }}>
              {imageURLs.map((item: any, i: any) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px 0',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className={styles.productImg} src={item.url} alt="" />
                    <p>{item.name}</p>
                  </div>
                  <div>
                    <DeleteIcon
                      onClick={() => removeImgFields(item, i)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
