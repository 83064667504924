import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Switch from 'react-switch';
import client from '../../api/client';
import { ReactComponent as BellIcon } from '../../assets/images/Icons/bell.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import { saveMeInfo } from '../../redux/actions/me/meActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { randnumber } from '../../utils/commonHelper';
import BackArrow from '../back/BackArrow';
import styles from './Navheading.module.scss';

interface propType {
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavBarHeading = ({ collapse, setCollapse }: propType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const routeName = path[path.length - 1];

  const me = useSelector((state) => state.meReducer);
  const envronment_detail = me?.meInfo?.envronmentDetail;
  const [notification, setNotifications] = useState([]);
  const [checked, setChecked] = useState<boolean>(
    envronment_detail?.name?.toLowerCase() == 'live'
  );
  const handleChecked = () => {
    toggleSwitch();
  };

  const [dropdown, setDropdown] = useState(false);
  const handleClickAway = () => {
    setDropdown(false);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const getMe = async () => {
    try {
      const data: any = await client.get('/subsidiary/dashboard/me');
      setNotifications(data?.data?.notifications);
      dispatch(saveMeInfo(data?.data));
      setChecked(data?.data?.envronmentDetail?.name === 'Live');
    } catch (error: any) {
      if (error?.response?.status === 401) {
        sessionStorage.clear();
        window.location.href = '/';
      } else {
        const message = error?.response?.data?.message;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const toggleSwitch = async () => {
    try {
      const data: any = await client.patch(
        '/subsidiary/dashboard/environment/toggle',
        {
          environmentId: checked ? 1 : 2,
          SetDefault: true,
        }
      );
      dispatch(
        openToastAndSetContent({
          toastContent: data?.data?.message,
          toastStyles: ToastSuccessStyles,
        })
      );
      setTimeout(() => {
        setChecked(!checked);
        window.location.reload();
      }, 200);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const { navbarRoute } = useSelector((state) => state.navbarReducer);
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const signOutHandler = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  const subsidiary_details = subsidiaryInfo?.data?.subsidiaryDetails;
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <p
              onClick={signOutHandler}
              style={{ width: '100px', paddingTop: '20px' }}
            >
              {' '}
              Log Out{' '}
            </p>
          ),
        },
      ]}
    />
  );
  const menuBell = (
    <Menu
      items={[
        {
          key: randnumber(10),
          label: (
            <div
              style={{
                maxWidth: '500px',
                paddingTop: '20px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {notification?.length > 0 ? (
                notification?.map((item: any) => (
                  <div
                    key={randnumber(10)}
                    style={{
                      borderBottom: '1px solid #ededed',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                    }}
                  >
                    <div className={styles.notificationTop}>
                      <div className={styles.messageFrom}>
                        {item?.messageFrom}
                      </div>
                      <div className={styles.date}>
                        {moment(item?.dateCreated, 'YYYYMMDD').fromNow()}
                      </div>
                    </div>
                    <div key={item?.id} className={styles.messageText}>
                      {item?.messageText}
                    </div>
                  </div>
                ))
              ) : (
                <p
                  style={{
                    maxWidth: '500px',
                    paddingTop: '20px',
                    color: 'black',
                  }}
                >
                  No New Notification Yet
                </p>
              )}
            </div>
          ),
        },
      ]}
    />
  );

  const Image =
    'https://i.ibb.co/fH4x0Xk/360-F-346936114-Rax-E6-OQogebg-AWTal-E1myse-Y1-Hbb5q-PM.jpg';

  return (
    <div
      className={styles.wrapper}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {/* <div className='mx-2 flex items-center'>
				<Input
					placeholder='Search'
					className={`w-[250px] rounded-full ${
						clickEvent ? 'flex' : 'hidden'
					} ease-in-out transition-all `}
				/>
				<div
					onClick={() => setClickEvent(!clickEvent)}
					className='ml-[-20px] z-50 cursor-pointer '>
					<img src={Search} alt='' />
				</div>
			</div> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{ cursor: 'pointer', marginRight: '20px' }}
          onClick={() => setCollapse(!collapse)}
        >
          {collapse ? (
            <MenuUnfoldOutlined
              style={{ fontSize: '20px', color: '#000000' }}
            />
          ) : (
            <MenuFoldOutlined style={{ fontSize: '20px', color: '#000000' }} />
          )}
        </div>
        <div style={{ fontSize: '17px' }} className={styles.navigationBack}>
          <BackArrow /> <div>{routeName.toUpperCase().replace('-', ' ')}</div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={styles.switchStatement}>
          <p style={{ color: '#757575' }} className={styles.switchText}>
            Test
          </p>
          <div style={{ paddingTop: '15px', margin: '0 10px' }}>
            <Switch
              checked={checked}
              onChange={handleChecked}
              offColor="#757575"
              height={24}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#19943c"
            />
          </div>
          <p style={{ color: '#19943c' }} className={styles.switchText}>
            Live
          </p>
        </div>
        <Dropdown overlay={menuBell} placement="bottom" overlayClassName="drop">
          <div
            style={{
              display: 'flex',
              margin: '0 28px',
              cursor: 'pointer',
              alignItems: 'center',
            }}
          >
            <Badge count={notification?.length} offset={[10, 0]}>
              <BellIcon />
            </Badge>
          </div>
        </Dropdown>

        <Dropdown
          overlay={menu}
          placement="bottom"
          overlayClassName="drop"
          arrow
        >
          <div
            style={{
              margin: '0 4px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Avatar
              size="large"
              src={subsidiary_details?.subsidiaries[0]?.subsidiaryLogo || Image}
              style={{
                color: '#fffff',
                background: '#19943c',
                marginRight: '16px',
                cursor: 'pointer',
              }}
            />
            <CaretDownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
// onClick={logOutHandler}
