import { Divider, Form, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import client from '../../../api/client';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './BusinessRegistration.module.scss';
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

function AddStakeholder({ handleCancel, getStake }: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const INITIALVALUES2 = {
    bvn: '',
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    mobileNumber: '',
  };

  const resetCancel = () => {
    handleCancel();
    form.resetFields();
  };

  const updatedStakeHandler = (values: any) => {
    dispatch(openLoader());
    client
      .post(`/subsidiary/dashboard/onboarding/stakeholder/add`, {
        ...values,
        mobileNumber: `+234${values.mobileNumber}`,
      })
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        getStake();
        resetCancel();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  return (
    <Form
      form={form}
      initialValues={INITIALVALUES2}
      layout="vertical"
      name="nest-messages-add"
      onFinish={updatedStakeHandler}
      validateMessages={validateMessages}
    >
      <div className={styles.flexInput}>
        <Form.Item
          style={{ flex: 1, marginRight: '16px' }}
          label="First name"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input style={{ borderRadius: '7px' }} className={styles.input} />
        </Form.Item>

        <Form.Item
          style={{ flex: 1 }}
          label="Last name"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input style={{ borderRadius: '7px' }} className={styles.input} />
        </Form.Item>
      </div>
      <div className={styles.flexInput}>
        <Form.Item
          style={{ flex: 1, marginRight: '16px' }}
          name="gender"
          label="Gender"
          className="selectOptions"
          rules={[{ required: true }]}
        >
          <Select allowClear>
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Date of Birth"
          name="dateOfBirth"
          rules={[{ required: true }]}
        >
          <Input
            style={{ borderRadius: '7px' }}
            className={styles.input}
            placeholder="YYYY-MM-DD"
            type="date"
          />
        </Form.Item>
      </div>
      <div className={styles.flexInput}>
        <Form.Item
          style={{ flex: 1, marginRight: '16px' }}
          label="Phone number"
          name="mobileNumber"
          rules={[
            {
              required: true,
            },
            { min: 10, message: 'Mobile number must be 10 digits' },
            { max: 10, message: 'Mobile number must be 10 digits' },
            {
              validator: (_, value) =>
                /[0-9]/.test(value)
                  ? Promise.resolve()
                  : Promise.reject('mobile number should contain number'),
            },
          ]}
        >
          <Input
            style={{ borderRadius: '7px', width: '100%', marginTop: '10px' }}
            addonBefore="+234"
            className={styles.input}
            placeholder="8107061526"
          />
        </Form.Item>

        <Form.Item
          style={{ flex: 1 }}
          name="bvn"
          label="BVN"
          rules={[{ required: true }]}
        >
          <Input style={{ borderRadius: '7px' }} className={styles.input} />
        </Form.Item>
      </div>
      <Divider />
      <div className={styles.buttonflex}>
        <div onClick={resetCancel} className={styles.cancel}>
          Back
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <button type="submit" className={styles.submitHandler}>
              Save
            </button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}

export default AddStakeholder;
