import React from 'react';
import { useDispatch } from 'react-redux';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import OutlineTextInput from '../../input/OutlineTextInput';
import styles from './Virtualwalletmodal.module.scss';

const VirtualWalletModal = ({ closeModal, fetchVirtualWallets }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    // first_name: string;
    // email: string;
    // last_name: string;
    // mobile_number: string;
    bvn?: string | undefined;
  }

  const initProps: initTypes = {
    // first_name: "",
    // email: "",
    // last_name: "",
    // mobile_number: "+234",
    bvn: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      // inputs.first_name &&
      // inputs.email &&
      // inputs.last_name &&
      // inputs.mobile_number &&
      inputs?.bvn?.length === 11
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const createVirtualWallet = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        '/subsidiary/create/static/account',
        inputs
      );

      const message = data?.data?.message;
      fetchVirtualWallets();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createVirtualWallet();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New virtual account</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          {/* <OutlineTextInput
            handleChange={updateProps}
            inputName="first_name"
            inputLabel="First name"
            inputValue={inputs.first_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="last_name"
            inputLabel="Last name"
            inputValue={inputs.last_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email address"
            inputValue={inputs.email}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile_number"
            inputLabel="Mobile Number"
            inputValue={inputs.mobile_number}
          /> */}

          <OutlineTextInput
            handleChange={updateProps}
            inputName="bvn"
            inputLabel="BVN"
            inputValue={inputs.bvn}
            inputType="number"
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new wallet
          </div>
        ) : (
          <div className={styles.notClickable}>Create new wallet</div>
        )}
      </div>
    </div>
  );
};

export default VirtualWalletModal;
