import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import { ReactComponent as AccountIcon } from '../../../assets/images/Icons/account.svg';
import Logo from '../../../assets/images/Logos/paymentgateway.png';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { getEnvironment } from '../../../utils/getEnvironmentConfig';
import styles from './Verifyemail.module.scss';

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getStartedState } = useSelector(
    (state) => state.getStartedStateReducer
  );

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .get(
        `/subsidiary/dashboard/resend/verification/email?email=${getStartedState}`
      )
      .then((data: any) => {
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };

  const handleSubmitTest = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .get(
        `/subsidiary/dashboard/verify/business/email?token=${encodeURIComponent(
          getStartedState
        )}`
      )
      .then((data: any) => {
        sessionStorage.clear();
        history.push('/');
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} alt="PgMerchant" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>
          A mail has been sent to your email address {getStartedState}, verify
          to sign in.
          <div className={styles.createlink} onClick={handleSubmit}>
            Resend Link
          </div>
          {getEnvironment() == 'DEV' && getStartedState && (
            <div
              className={styles.createlink + ' ' + styles.createlinkTest}
              onClick={handleSubmitTest}
            >
              Verify Test Mode
            </div>
          )}
        </div>
      </div>
      <div className={styles.newAccount}>
        <div className={styles.noAccount}>Back to sign in page</div>
        <div
          className={styles.create}
          onClick={() => {
            history.push('/');
          }}
        >
          <AccountIcon /> Sign In
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
