import { Layout } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom';
import { NavBarHeading } from '../components/navbarheading';
import { SideDrawer } from '../components/sideDrawer';
import AuditTrails from '../views/AuditTrails/AuditTrails';
import BalanceHistory from '../views/BalanceHistory/BalanceHistory';
import Balances from '../views/Balances/Balances';
import Beneficiary from '../views/beneficiary/Beneficiary';
import Business from '../views/Business/Business';
import Assessment from '../views/Chargeback/Assessment/Assessment';
import Awaitingresponse from '../views/Chargeback/Awaitingresponse/Awaitingresponse';
import Chargeback from '../views/Chargeback/Chargeback';
import Lost from '../views/Chargeback/Lost/Lost';
import Pending from '../views/Chargeback/Pending/Pending';
import Won from '../views/Chargeback/Won/Won';
import Compliance from '../views/Compliance/Compliance';
import ComplianceInformation from '../views/ComplianceInfo/ComplianceInfo';
import CustomerDetails from '../views/CustomerDetails/CustomerDetails';
import Customers from '../views/Customers/Customers';
import Dashboard from '../views/Dashboard/Dashboard';
import Disputes from '../views/Disputes/Disputes';
import Fundinghistory from '../views/funding/Fundinghistory';
import GetStarted from '../views/GetStarteds/GetStarted';
import Invoice from '../views/Invoice/Invoice';
import NewInvoice from '../views/Invoice/NewInvoice/NewInvoice';
import BankDetails from '../views/Onboardings/bankDetails/BankDetails';
import BusinessInformation from '../views/Onboardings/businessInformation/BusinessInformation';
import BusinessRegistration from '../views/Onboardings/businessRegistration/BusinessRegistration';
import ContactInformation from '../views/Onboardings/contactInformation/ContactInformation';
import Onboarding from '../views/Onboardings/onboardinglist/Onboarding';
import PersonalInformation from '../views/Onboardings/personalInformation/PersonalInformation';
import Terms from '../views/Onboardings/terms/Terms';
import Tour from '../views/Onboardings/tour/Tour';
import PaymentLinks from '../views/PaymentLinks/PaymentLinks';
import PayoutAccounts from '../views/PayoutAccounts/PayoutAccounts';
import Payouts from '../views/Payouts/Payouts';
import PendingApprovals from '../views/PendingApprovals/PendingApprovals';
import Preferences from '../views/Preferences/Preferences';
import NewProduct from '../views/Products/NewProduct/NewProduct';
import Products from '../views/Products/Products';
import Refund from '../views/Refund/Refund';
import Refunds from '../views/Refunds/Refunds';
import RefundDetails from '../views/RefundsDetails/RefundDetails';
import Roles from '../views/Roles/Roles';
import RollingReserve from '../views/RollingReserves/RollingReserve';
import Settlements from '../views/Settlements/Settlements';
import Subsidiary from '../views/Subsidiary/Subsidiary';
import TransactionDetails from '../views/TransactionDetails/TransactionDetails';
import Transactions from '../views/Transactions/Transactions';
import Transfer from '../views/Transfer/Transfer';
import VirtualWallets from '../views/VirtualWallets/VirtualWallets';
import Webhooks from '../views/Webhooks/Webhooks';
import styles from './DashboardRoutes.module.scss';
// import ScrollToTop from "../ScrollToTop";
import { useDispatch, useSelector } from 'react-redux';
import client from '../api/client';
import { closeLoader, openLoader } from '../redux/actions/loader/loaderActions';
import { saveOnboardLevelInfo } from '../redux/actions/onboardlevel/onboardLevelAction';
import ChargebackDetails from '../views/Chargeback/ChargebackDetails/ChargebackDetails';
import SettlementGrouped from '../views/Settlements/SettlementGrouped';
import User from '../views/User/User';
// import { getOnboardLevelMe } from '../utils/onboardLevel';

const { Header, Sider, Content } = Layout;

export default function DashboardRoutes() {
  const history = useHistory();
  const [collapse, setCollapse] = useState(false);
  const dispatch = useDispatch();
  const { onBoardRoute, started } = useSelector(
    (state) => state.onboardLevelInfoReducer
  );

  const { onVerificationState } = useSelector(
    (state) => state.onboardingStateReducer
  );

  const { meInfo } = useSelector((state) => state.meReducer);
  const defaultbusiness = meInfo?.subsidiaryDetails?.subsidiaries.find(
    (x: any) => x.isDefault
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);
  const getOnboardLevelMe = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.get(
        '/subsidiary/dashboard/onboarding/percentage'
      );
      dispatch(saveOnboardLevelInfo(data?.data));
    } catch (error: any) {
      if (error?.response?.data?.message === 'Please login again') {
        sessionStorage.clear();
        window.location.href = '/';
      } else {
        // const message = error?.response?.data?.message;
        // dispatch(
        //   openToastAndSetContent({
        //     toastContent: message,
        //     toastStyles: ToastErrorStyles,
        //   })
        // );
      }
    } finally {
      dispatch(closeLoader());
    }
  };

  useLayoutEffect(() => {
    getOnboardLevelMe();
  }, [history.location.pathname]);

  // useEffect(() => {
  // }, [onVerificationState, onBoardRoute]);
  return (
    <Router>
      <Layout
        style={{
          position: 'relative',
          overflowY: 'hidden',
          height: '100vh',
          background: '#f8f8f8',
        }}
      >
        <Sider
          trigger={null}
          width="250px"
          breakpoint="md"
          collapsedWidth=""
          onBreakpoint={(broken) => {
            setCollapse(broken);
          }}
          collapsible
          collapsed={collapse}
          className={styles.scrollbarhide}
          style={{
            zIndex: 10,
            height: '100vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
            background: '#ffffff',
          }}
        >
          <SideDrawer collapse={collapse} />
        </Sider>
        <Layout style={{ margin: '0 0', background: '#F8F8F8' }}>
          <Header
            style={{
              position: 'sticky',
              zIndex: 1,
              width: '100%',
              top: 0,
              right: 0,
              background: '#ffffff',
              height: '74px',
              padding: '0 20px',
            }}
          >
            <NavBarHeading collapse={collapse} setCollapse={setCollapse} />
          </Header>
          <Content
            className={styles.scrollbarhide}
            style={{
              marginTop: '32px',
              overflowY: 'scroll',
              margin: '20px 20px',
            }}
          >
            <Switch>
              <Route exact path="/dashboard/">
                {defaultbusiness?.status == 'Active' ? (
                  <Dashboard />
                ) : defaultbusiness?.status == 'Pending-Approval' ? (
                  <Dashboard />
                ) : onVerificationState !== 'Pending-Approval' &&
                  onBoardRoute ? (
                  <Redirect to="/dashboard/getStarted" />
                ) : onVerificationState !== 'Pending-Approval' &&
                  !onBoardRoute ? (
                  <Redirect to="/dashboard/getStarted/onboarding" />
                ) : (
                  <Dashboard />
                )}
              </Route>
              <Route
                exact
                path="/dashboard/getStarted"
                component={GetStarted}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/onboarding"
                component={Onboarding}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/personal-Information"
                component={PersonalInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/business-Information"
                component={BusinessInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/contact-Information"
                component={ContactInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/business-Registration"
                component={BusinessRegistration}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/bank-details"
                component={BankDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/terms"
                component={Terms}
              ></Route>
              <Route
                exact
                path="/dashboard/getStarted/tour"
                component={Tour}
              ></Route>

              <Route
                exact
                path="/dashboard/compliance"
                component={Compliance}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions"
                component={Transactions}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice"
                component={Invoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice/new-invoice"
                component={NewInvoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers"
                component={Customers}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products"
                component={Products}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products/new-product"
                component={NewProduct}
              ></Route>
              <Route
                exact
                path="/dashboard/business/payment-links"
                component={PaymentLinks}
              ></Route>
              <Route
                exact
                path="/dashboard/business/refunds"
                component={Refunds}
              ></Route>
              <Route
                exact
                path="/dashboard/business/disputes"
                component={Disputes}
              ></Route>
              <Route
                exact
                path="/dashboard/account/balances"
                component={Balances}
              ></Route>
              <Route
                exact
                path="/dashboard/balance/rolling-reserve"
                component={RollingReserve}
              ></Route>

              <Route
                exact
                path="/dashboard/balance/history"
                component={BalanceHistory}
              ></Route>
              <Route
                exact
                path="/dashboard/account/virtual-wallets"
                component={VirtualWallets}
              ></Route>
              <Route
                exact
                path="/dashboard/account/settlements"
                component={Settlements}
              ></Route>
              <Route
                exact
                path="/dashboard/account/settlements/grouped"
                component={SettlementGrouped}
              ></Route>
              <Route
                exact
                path="/dashboard/account/payouts"
                component={Payouts}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/transfer"
                component={Transfer}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/pending-approval"
                component={PendingApprovals}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/beneficiaries"
                component={Beneficiary}
              ></Route>
              <Route
                exact
                path="/dashboard/payout/funding-history"
                component={Fundinghistory}
              ></Route>
              <Route exact path="/dashboard/refunds" component={Refund}></Route>

              <Route
                exact
                path="/dashboard/settings/business"
                component={Business}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/compliance-information"
                component={ComplianceInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/payout-accounts"
                component={PayoutAccounts}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/roles-permissions"
                component={Roles}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/subsidiaries"
                component={Subsidiary}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/users"
                component={User}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/preference"
                component={Preferences}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/audit-trails"
                component={AuditTrails}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback"
                component={Chargeback}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/:id/details"
                component={ChargebackDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/pending"
                component={Pending}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/awaiting-response"
                component={Awaitingresponse}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/won"
                component={Won}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/lost"
                component={Lost}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/assessment"
                component={Assessment}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers/customer-details/:id"
                component={CustomerDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions/transaction-details/:id"
                component={TransactionDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/refunds/refund-details/:id"
                component={RefundDetails}
              ></Route>

              <Route
                exact
                path="/dashboard/settings/api-webhooks"
                component={Webhooks}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}
