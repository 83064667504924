import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { Fragment, useState } from 'react';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import styles from './Payment.module.scss';
// import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import CopyIcon from '../../../assets/images/Icons/copy.svg';
import LinkOffIcon from '../../../assets/images/Icons/link-off.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import { customStyles } from '../../../constants/modalStyles';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import PaymentModal from '../../ModalContent/PaymentModal/PaymentModal';
import Menus from '../Menus/Menus';

type MenuProps = {
  menuContent: string;
};
const PaymentLinkMenu = ({
  reference,
  status,
  fetchPaymentLink,
  dataInfo,
}: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  const [copied, setCopied] = useState(false);

  function notify(message: string = 'Payment link copied') {
    dispatch(
      openToastAndSetContent({
        toastContent: message,
        toastStyles: ToastSuccessStyles,
      })
    );
  }

  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const body = {
    action: status === true ? 'disable' : 'activate',
    reference,
  };

  const updatePaymentLink = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        '/subsidiary/dashboard/payment/link/update-status',
        body
      );
      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const getLinkByReference = () => {
    setReferenceData(dataInfo);
  };

  const copyPaymentLink = () => {};
  const handleStatus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    closeModal();
    getLinkByReference();
    updatePaymentLink();
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={LinkOffIcon}
                optionName={
                  status === true ? 'Deactivate Link' : 'Activate Link'
                }
                onclick={() => setIsOpen(true)}
              />
              <Menus
                icon={ViewIcon}
                optionName="View Details"
                onclick={() => {
                  setIsOpenDetails(true);
                  getLinkByReference();
                }}
              />

              <Menus
                icon={CopyIcon}
                optionName="Copy Link"
                onclick={() => {
                  navigator.clipboard.writeText(dataInfo?.paymentLinkUrl);
                  notify();
                }}
              />
              <Menus
                icon={ViewIcon}
                optionName="Initiate Payment"
                onclick={() =>
                  window.open(dataInfo?.paymentLinkUrl, '_blank')?.focus()
                }
              />
              <Menus
                icon={CopyIcon}
                optionName="Copy Payment Link Id"
                onclick={() => {
                  navigator.clipboard.writeText(dataInfo?.id);
                  notify('Payment link id copied');
                }}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        {status === true ? (
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <div className={styles.background}>
                <div className={styles.linkDetails}>Deactivate link</div>
                <CloseIcon onClick={closeModal} />
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.confirmation}>
                Are you sure you want to deactivate this link?
              </div>
              <div className={styles.link}>
                Deactivating this link will make it no longer accessible.
              </div>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.cancel} onClick={closeModal}>
                No, cancel
              </div>
              <div
                className={styles.deactivate}
                onClick={(e) => {
                  handleStatus(e);
                }}
              >
                Yes, deactivate link
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <div className={styles.background}>
                <div className={styles.linkDetails}>Activate link</div>
                <CloseIcon onClick={closeModal} />
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.confirmation}>
                Are you sure you want to Activate this link?
              </div>
              <div className={styles.link}>
                Activating this link will make it accessible.
              </div>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.cancel} onClick={closeModal}>
                No, cancel
              </div>
              <div
                className={styles.deactivate}
                onClick={(e) => {
                  handleStatus(e);
                }}
              >
                Yes, activate link
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        <PaymentModal
          closeModal={closeModalDetails}
          reference={referenceData}
        />
      </Modal>
    </Fragment>
  );
};

export default PaymentLinkMenu;
