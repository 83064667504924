import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import Img from '../../../assets/IconsMerchant/landing.png';
import hidePwdImg from '../../../assets/images/Icons/hide-password.svg';
import showPwdImg from '../../../assets/images/Icons/show-password.svg';
import logo from '../../../assets/images/Logos/paymentgateway.png';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { getStartedState } from '../../../redux/actions/getStarted/getStarted';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './Signup.module.scss';

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const SignUp = () => {
  const todaysDate = new Date();
  const [valid, setValid] = useState<boolean>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const INITIALVALUES = {
    firstName: '',
    lastName: '',
    userEmail: '',
    password: '',
    mobileNumber: '',
  };
  interface initTypes {
    firstName: string;
    lastName: string;
    userEmail: string;
    password: string;
    confirmpassword: string;
  }
  const initProps: initTypes = {
    firstName: '',
    lastName: '',
    userEmail: '',
    password: '',
    confirmpassword: '',
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#016E20',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#016E20',
      fontFamily: "'Kumbh Sans'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      maxWidth: 260,
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
  }));
  const [inputs, setInputs] = React.useState(initProps);

  // const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
  // 	const reg = new RegExp('[a-z]');

  // 	const newValue = event.target.value;
  // 	setValid(reg.test(newValue));
  // 	const inputName = event.target.name;
  // 	setInputs((prevState: initTypes) => {
  // 		return {
  // 			...prevState,
  // 			[inputName]: newValue,
  // 		};
  // 	});
  // };

  // let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

  const checkPasswordEquals = () => {
    const password = form.getFieldValue('password');
    const confirmpassword = form.getFieldValue('confirmpassword');
    return password == confirmpassword;
  };

  const handleSubmit = (values: initTypes) => {
    if (!checkPasswordEquals()) {
      return dispatch(
        openToastAndSetContent({
          toastContent: 'Passwords do not match',
          toastStyles: ToastErrorStyles,
        })
      );
    }
    const pp = { ...values };
    pp.password = btoa(pp.password ?? '');
    pp.confirmpassword = btoa(pp.confirmpassword ?? '');
    dispatch(openLoader());
    client
      .post('/subsidiary/dashboard/signup', pp)
      .then((data: any) => {
        // history.push("/login")
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        dispatch(getStartedState(values.userEmail));
        history.push('/verify');
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  return (
    <div className={styles.signWrapper}>
      <div className={styles.leftwrapper}>
        <div className={styles.image}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.formwrapper}>
          <h1 className={styles.form_heading}>Create an Account</h1>
          <p className={styles.form_p}>Enter your details to get started</p>
          <div className={styles.businessForm}>
            <Form
              form={form}
              initialValues={INITIALVALUES}
              layout="vertical"
              name="nest-messages"
              onFinish={handleSubmit}
              validateMessages={validateMessages}
            >
              <div className={styles.flexInput}>
                <Form.Item
                  style={{ flex: 1, marginRight: '16px' }}
                  label=""
                  name="firstName"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: (_, value) =>
                        /^[a-z ,.'-]+$/i.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              'First and middle name must only contain alphabet'
                            ),
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                    placeholder="First and Middle name"
                  />
                </Form.Item>

                <Form.Item
                  style={{ flex: 1 }}
                  name="lastName"
                  label=""
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: (_, value) =>
                        /^([A-Za-z])+$/.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              'Last name must only contain alphabet'
                            ),
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                    placeholder="Last name"
                  />
                </Form.Item>
              </div>
              <Form.Item
                style={{ flex: 1 }}
                name="userEmail"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  placeholder="Email Address"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="MobileNumber"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Please enter your mobile number',
                  },
                  {
                    type: 'string',
                    message: 'Please enter a valid mobile number',
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  placeholder="Mobile Number"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="name"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Please enter business name',
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: '7px' }}
                  className={styles.input}
                  placeholder="Business name"
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="password"
                label=""
                rules={[
                  {
                    required: true,
                  },
                  { min: 8 },
                  {
                    validator: (_, value) =>
                      value.trim() &&
                      /[A-Z]/.test(value) &&
                      /[a-z]/.test(value) &&
                      /[0-9]/.test(value) &&
                      /[.!@#$&*_]/.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            'Password must contain atleast one lowercase, uppercase, digit & special case letter{.!@#$&*_}.'
                          ),
                  },
                ]}
              >
                {/* <Input
                  type="password"
                  style={{ borderRadius: "7px" }}
                  className={styles.input}
                  placeholder="Password"
                /> */}
                <div className={styles.passwordInp}>
                  <Input
                    type={isRevealPwd ? 'text' : 'password'}
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                    placeholder="Password"
                  />
                  <img
                    alt="/"
                    className={styles.inputImg}
                    title={isRevealPwd ? 'Hide password' : 'Show password'}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                </div>
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="confirmpassword"
                label=""
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      checkPasswordEquals()
                        ? Promise.resolve()
                        : Promise.reject('Password does not match'),
                  },
                ]}
              >
                <div className={styles.passwordInp}>
                  <Input
                    type={isRevealPwd ? 'text' : 'password'}
                    style={{ borderRadius: '7px' }}
                    className={styles.input}
                    placeholder="Confirm Password"
                  />
                  <img
                    alt="/"
                    className={styles.inputImg}
                    title={isRevealPwd ? 'Hide password' : 'Show password'}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                </div>
              </Form.Item>
              <Divider />

              <Form.Item shouldUpdate>
                {() => (
                  <button
                    type="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                    className={styles.buttonNext}
                  >
                    Next
                  </button>
                )}
              </Form.Item>
            </Form>
            <div className={styles.terms_and_condition}>
              By clicking this "Create Account" Button, you agree to the{' '}
              <a href="https://payinvert.com/terms-conditions/">Terms</a> of
              acceptable use,{' '}
              <a href="https://payinvert.com/privacy-policy/">Privacy Policy</a>{' '}
              and <a href="https://payinvert.com/cookies/">Cookie Policy</a>
            </div>
            {/* <form onSubmit={handleSubmit} className={styles.form}>
							<>
								<h3 className={styles.businessInfo}>Personal Information</h3>
								<div className={styles.nameflex}>
									<OutlineTextInput
										style={{ marginTop: '10px' }}
										handleChange={updateProps}
										inputName='firstName'
										inputLabel='First name'
										inputValue={inputs.firstName}
									/>
									<OutlineTextInput
										style={{ marginTop: '10px' }}
										handleChange={updateProps}
										inputName='lastName'
										inputLabel='Last name'
										inputValue={inputs.lastName}
									/>
								</div>

								<OutlineTextInput
									style={{ marginTop: '20px' }}
									handleChange={updateProps}
									inputName='userEmail'
									inputLabel='Email Address'
									inputValue={inputs.userEmail}
								/>

								<OutlineTextInput
									style={{ marginTop: '20px' }}
									handleChange={updateProps}
									inputName='password'
									inputLabel='Password'
									inputType='password'
									inputValue={inputs.password}
								/>
								<button
									type='submit'
									disabled={disabledFunction()}
									className={styles.buttonNext}>
									Next
								</button>
							</>
						</form> */}
          </div>
        </div>
        <p className={styles.createuser}>
          Existing User?{' '}
          <span
            onClick={() => {
              history.push('/');
            }}
            className={styles.forgetPasswordspan}
          >
            Sign In
          </span>
        </p>
      </div>
      <div className={styles.rightwrapper}>
        <img className={styles.rightwrapper_img} src={Img} alt="" />
      </div>
    </div>
  );
};

export default SignUp;
