import { Button, Form, Input, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../api/client';
import utils from '../../api/utilities';
import { ReactComponent as IndividualIcon } from '../../assets/IconsMerchant/individualpick.svg';
import { ReactComponent as BusinessIcon } from '../../assets/IconsMerchant/registerpick.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import useApi from '../../hooks/useApi';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { onboardingState as onboardingfunc } from '../../redux/actions/onboardingState/onbodardingState';
import { saveOnboardStateInfo } from '../../redux/actions/onboardlevel/onboardLevelAction';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import styles from './GetStarted.module.scss';

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

interface categoryType {
  name: string;
  description: string;
  id: number;
  dateCreated: string;
  dateUpdated: null | string;
  dateDeleted: null | string;
}

interface countryType {
  name: string;
  countryCode: string;
  currency: string;
  currencyId: number;
  shortName: string;
  isOfac: null | string;
  isEuro: null | string;
  allowed: boolean;
  id: number;
  dateCreated: string;
  dateDeleted: string;
}

function GetStarted() {
  const bCategoryApi = useApi(utils.getBusinessCategories);
  const bSizeApi = useApi(utils.getBusinessSize);
  const countryApi = useApi(utils.getCountries);
  const categories: categoryType[] = useMemo(
    () => bCategoryApi?.data?.data ?? [],
    [bCategoryApi]
  );
  const businessSizes: categoryType[] = bSizeApi?.data?.data ?? [];
  const countries: countryType[] = countryApi?.data?.data ?? [];

  const { onboardingState } = useSelector(
    (state) => state.onboardingStateReducer
  );

  const history = useHistory();
  const res = useSelector(
    (state) => state?.onboardLevelInfoReducer?.onboardLevelInfo
  );

  const subsidiaries = useSelector(
    (state) => state?.meReducer?.meInfo?.subsidiaryDetails?.subsidiaries
  );
  const subsidiaryName = subsidiaries[0]?.name || '';

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  // Validate form fields on change
  const handleFormChange = async () => {
    try {
      await form.validateFields();
      setDisabled(false);
    } catch (error) {
      setDisabled(true);
    }
  };
  useEffect(() => {
    const getCategory = (name: string) => {
      const ff = categories.find((x) => x.name === name);
      return ff?.id ?? categories[0]?.id;
    };
    const getBusinessInfo = () => {
      dispatch(openLoader());
      client
        .get(`/subsidiary/dashboard/onboarding/business/information/view`)
        .then((data: any) => {
          const ddata = data?.data?.data;
          if (ddata) {
            form.setFieldValue('businessSize', ddata.businessSize);
            form.setFieldValue('isDeveloper', ddata.isDeveloper);
            form.setFieldValue(
              'businessCategoryId',
              getCategory(ddata.businessRegisteredCategory)
            );
          }
        })
        .catch((error: any) => {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        })
        .finally(() => {
          dispatch(closeLoader());
        });
    };
    getBusinessInfo();
  }, [categories, dispatch, form]);

  const INITIALVALUES = {
    businessName: subsidiaryName,
    country: '',
    businessType: onboardingState,
    businessSize: '',
    isDeveloper: 'true',
    businessCategoryId: '',
    referal_code: '',
  };

  const identifierChange = (type: string) => {
    dispatch(onboardingfunc(type));
    form.setFieldValue('businessType', type);
  };

  useEffect(() => {
    bCategoryApi.request();
    bSizeApi.request();
    countryApi.request();
  }, []);

  useEffect(() => {
    const trigger = () => {
      if (
        (res?.data?.personalInformation === 100 ||
          res?.data?.businessCompliance === 100) &&
        res?.data?.businessInformation === 100 &&
        res?.data?.bankInformation === 100 &&
        res?.data?.termsAndCondition === 100
      ) {
        dispatch(saveOnboardStateInfo(false));
      } else {
        dispatch(saveOnboardStateInfo(true));
      }
    };
    trigger();
  }, [dispatch, res]);
  const updatedHandler = async (values: any) => {
    dispatch(openLoader());
    const payload = {
      ...values,
      isDeveloper:
        values.isDeveloper === 'true' || values.isDeveloper === true
          ? true
          : false,
    };
    await client
      .post(`/subsidiary/dashboard/business/get-started`, payload)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        history.push('/dashboard/getStarted/onboarding');
        window.location.reload();
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapperh2}>Tell us about your business</h2>
      <p className={styles.wrapperp}>
        A few more things to help us set up your dashboard
      </p>
      <div className={styles.wrapperbox}>
        <Form
          form={form}
          initialValues={INITIALVALUES}
          layout="vertical"
          name="nest-messages"
          onFinish={updatedHandler}
          validateMessages={validateMessages}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="businessName"
            label="Business/Company name"
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <Input
              className={styles.input}
              placeholder="Business/Company name"
              style={{ borderRadius: '7px' }}
            />
          </Form.Item>
          <Form.Item
            className="selectOptions"
            label="Where is your business based?"
            style={{ flex: 1 }}
            name="country"
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <select
              className={styles.formSelect}
              placeholder="Where is your business based?"
            >
              <option value="" disabled>
                --Selet a Country--
              </option>

              {countries.length &&
                countries.map(({ name, shortName }, i) => (
                  <option key={i + name + Math.random()} value={shortName}>
                    {name}
                  </option>
                ))}
            </select>
          </Form.Item>
          <Form.Item
            name="businessType"
            label=" What is your business type?"
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <div
                onClick={() => identifierChange('Individual')}
                style={{
                  color: onboardingState === 'Individual' ? '#19943c' : '',
                  borderColor:
                    onboardingState === 'Individual' ? '#19943c' : '',
                }}
                className={styles.businessNameBox}
              >
                <div className={styles.boxleft}>
                  <IndividualIcon />
                </div>
                <div className={styles.boxright}>
                  <h3
                    style={{
                      color: onboardingState === 'Individual' ? '#19943c' : '',
                    }}
                  >
                    Starter/Individual
                  </h3>
                  <p>
                    An unregistered business e.g freelancers and sole traders
                  </p>
                </div>
              </div>
              <div
                onClick={() => identifierChange('Company')}
                style={{
                  color: onboardingState === 'Company' ? '#19943c' : '',
                  borderColor: onboardingState === 'Company' ? '#19943c' : '',
                }}
                className={styles.businessNameBox}
              >
                <div className={styles.boxleft}>
                  <BusinessIcon />
                </div>
                <div className={styles.boxright}>
                  <h3
                    style={{
                      color: onboardingState === 'Company' ? '#19943c' : '',
                    }}
                  >
                    Registered
                  </h3>
                  <p>A registered business with a corporate bank account</p>
                </div>
              </div>
            </Space>
          </Form.Item>
          <div className={styles.flexInput}>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1, marginRight: '16px' }}
              label="Business size"
              name="businessSize"
              rules={[{ required: true, message: 'Please select an option' }]}
            >
              <select placeholder="Business size" className={styles.formSelect}>
                <option value="" disabled>
                  --Selet a business size--
                </option>
                {businessSizes.map((item, i) => (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1 }}
              label="Are you a software developer?"
              name="isDeveloper"
              rules={[{ required: true, message: 'Please select an option' }]}
            >
              <select
                placeholder="Are you a software developer?"
                className={styles.formSelect}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </Form.Item>
          </div>
          <div className={styles.flexInput}>
            <Form.Item
              className="selectOptions"
              label="Business category"
              style={{ flex: 1, marginRight: '16px' }}
              name="businessCategoryId"
              rules={[{ required: true, message: 'Please select an option' }]}
            >
              <select
                className={styles.formSelect}
                placeholder="Business category"
              >
                {categories.length &&
                  [
                    { id: '000128928', name: '--Choose your category--' },
                    ...categories,
                  ]?.map(({ id, name }: any, i) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Form.Item>
            <Form.Item
              label="Referral code"
              style={{ flex: 1 }}
              name="referalCode"
              rules={[{ required: false }]}
            >
              <Input
                style={{ borderRadius: '7px' }}
                className={styles.input}
                placeholder="Enter referral code"
              />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate label={null}>
            <Button
              disabled={!disabled}
              htmlType="submit"
              type="primary"
              size="large"
              block
              className={styles.submitHandler}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default GetStarted;
