import { Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './AddUserModal.module.scss';
const { Option } = Select;

const AddUserModal = ({ closeModal, data }: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  interface initTypes {
    firstName: string | undefined;
    lastName: string | undefined;
    mobileNumber: string | undefined;
    email?: string | undefined;
    role?: string | undefined;
  }

  const initProps: initTypes = {
    firstName: undefined,
    lastName: undefined,
    mobileNumber: undefined,
    email: data?.emailAddress || undefined,
    role: data?.role,
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [emailExists, setEmailExists] = React.useState(
    data?.emailAddress ? '002' : ''
  );
  const [resolveLoading, setResolveLoading] = React.useState('');
  const [roles, setRoles] = React.useState([]);

  const disableFunction = () => {
    if (emailExists == '002' && inputs?.email) return false;
    if (
      emailExists != '002' &&
      inputs?.email &&
      inputs.firstName &&
      inputs.lastName &&
      inputs.mobileNumber
    )
      return false;
    if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0)
      return true;
    return true;
  };

  const getRolesHandler = async () => {
    const res: any = await client.get('/subsidiary/dashboard/users/roles');
    setRoles(res?.data?.roles ?? []);
  };

  const checkIfEmailExists = async (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (pattern.test(email)) {
      setResolveLoading('...');
      const data: any = await client.post('/subsidiary/dashboard/users/exist', {
        emailAddress: email,
      });
      setResolveLoading('');
      if (data?.data?.statusCode == '002') setEmailExists('002');
      else if (data?.data?.statusCode == '00') {
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: 'User already exist on this subsidiary',
          })
        );
        setEmailExists('00');
      } else setEmailExists('001');
    }
    return false;
  };
  const updateProps = async (event: any, name: string) => {
    const newValue = event?.target?.value ?? event;
    setInputs({ ...inputs, [name]: newValue });
    name == 'email' && (await checkIfEmailExists(newValue));
  };

  useEffect(() => {
    getRolesHandler();
  }, []);
  const createUserHandler = async (values: any) => {
    dispatch(openLoader());
    try {
      var path = data ? 'update' : 'create';
      const res: any = await client.post(
        `/subsidiary/dashboard/users/${path}`,
        { ...inputs }
      );
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      closeModal();
    } catch (error: any) {
      const { message } = error?.response?.data ?? { message: error.message };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createUserHandler(e);
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>
            {data ? 'Update User' : 'Invite User'}
          </div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <Form
          form={form}
          initialValues={inputs}
          layout="vertical"
          name="nest-messages"
          onFinish={handleSubmit}
          className={styles.form}
        >
          <Form.Item
            name="email"
            label={`User Email Address${resolveLoading}`}
            style={{ flex: 1 }}
            rules={[{ required: true }]}
          >
            <Input
              disabled={data}
              className={styles.input}
              placeholder="User Email Address"
              style={{ borderRadius: '7px' }}
              onChange={(v) => updateProps(v, 'email')}
              value={inputs?.email}
            />
          </Form.Item>
          {emailExists == '001' && (
            <div>
              <Form.Item
                name="firstName"
                label="First Name"
                style={{ flex: 1 }}
              >
                <Input
                  className={styles.input}
                  placeholder="John"
                  style={{ borderRadius: '7px' }}
                  onChange={(v) => updateProps(v, 'firstName')}
                  value={inputs?.firstName}
                />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" style={{ flex: 1 }}>
                <Input
                  className={styles.input}
                  placeholder="Doe"
                  style={{ borderRadius: '7px' }}
                  onChange={(v) => updateProps(v, 'lastName')}
                  value={inputs?.lastName}
                />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                style={{ flex: 1 }}
              >
                <Input
                  className={styles.input}
                  placeholder="+2348103465"
                  style={{ borderRadius: '7px' }}
                  onChange={(v) => updateProps(v, 'mobileNumber')}
                  value={inputs?.mobileNumber}
                />
              </Form.Item>
            </div>
          )}
          <Form.Item
            name="role"
            label="Role"
            style={{ flex: 1 }}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Roles"
              allowClear
              onChange={(v) => updateProps(v, 'role')}
            >
              {roles.map((role: any) => (
                <Option key={role.name} value={role.name}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {!disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            {data ? 'Update' : 'Invite User'}
          </div>
        ) : (
          <div className={styles.notClickable}>
            {data ? 'Update' : 'Invite User'}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUserModal;
