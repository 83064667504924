import { Fragment, useState } from 'react';
import { ReactComponent as DropArrow } from '../../assets/IconsMerchant/export.svg';
import { ReactComponent as DropArrow2 } from '../../assets/images/Icons/drop-arrow.svg';
import styles from './Exportbutton.module.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { exportToXLS } from '../../utils/exportToExcel';

const ExportButton = ({ data, fileName, newButton, fetchDataHandler }: any) => {
  var dispatch = useDispatch();

  const [exportData, setExportData] = useState(data);

  const handleClick = () => {
    exportToXLS(data, fileName);
  };

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = async () => {
    if (
      !dropdown &&
      (exportData?.length < 1 || exportData?.length != data?.length)
    )
      await fetchExportData();
    setDropdown(!dropdown);
  };

  const fetchExportData = async () => {
    if (!fetchDataHandler) return;
    dispatch(openLoader());
    const d = (await fetchDataHandler()) || data || [];
    setExportData([...d]);
    dispatch(closeLoader());
  };
  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        {newButton ? (
          <div className={styles.exportMenu2} onClick={handleDropdown}>
            <div className={styles.filter2}>
              Export{' '}
              <span>
                <DropArrow2 className={styles.dropArrow2} />
              </span>
            </div>

            {dropdown && (
              <div className={styles.exportDropdown}>
                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                  Export as .xls
                </div>
                <CSVLink
                  data={data ? data : []}
                  //   headers={headers}
                  filename={fileName}
                  className={styles.csv}
                >
                  Export to .csv
                </CSVLink>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.exportMenu}>
            <div className={styles.export} onClick={handleDropdown}>
              <DropArrow className={styles.dropArrow} />
              &nbsp; Export
            </div>

            {dropdown && (
              <div className={styles.exportDropdown}>
                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                  Export as .xls
                </div>
                <CSVLink
                  data={data ? data : []}
                  //   headers={headers}
                  filename={fileName}
                  className={styles.csv}
                >
                  Export to .csv
                </CSVLink>
              </div>
            )}
          </div>
        )}
      </ClickAwayListener>
    </Fragment>
  );
};

export default ExportButton;
