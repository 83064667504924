import styles from './Statusview.module.scss';

type StausProps = {
  status: string;
  green?: string;
  red?: string;
  orange?: string;
  indigo?: string;
  blue?: string;
};
const StatusView = ({
  status,
  green,
  orange,
  red,
  indigo,
  blue,
}: StausProps) => {
  return (
    <div>
      {red?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.declined}>{status}</div>
      ) : orange?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.processing}>{status}</div>
      ) : green?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.completed}>{status}</div>
      ) : indigo?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.not}>{status}</div>
      ) : blue?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.new}>{status}</div>
      ) : (
        <div className={styles.declined}>{status}</div>
      )}
    </div>
  );
};

export default StatusView;
