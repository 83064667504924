import React, { useEffect, useState } from 'react';
import { ReactComponent as HighlightOffIcon } from '../../assets/IconsMerchant/imageclose.svg';
import { ReactComponent as LoopIcon } from '../../assets/IconsMerchant/rotate.svg';
import styles from './Business.module.scss';

import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import client, { formClient } from '../../api/client';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import StatusView from '../../components/StatusView/StatusView';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';

const Business = () => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLInputElement>(null);

  const [defaultSub, setDefaultSub] = useState<any>();
  // const { subsidiaryInfo } = useSelector((state) => state.subsidiaryInfoReducer);
  const { activeSubsidiary } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );
  // const subsidiaryDetails = subsidiaryInfo?.data?.subsidiaryDetails;
  // const getMe = async () => {
  // 	try {
  // 		const data: any = await client.get('/subsidiary/dashboard/me');
  // 		setDefaultSub(data?.data);
  // 		setInputs(data?.data?.subsidiaryDetails?.subsidiaries[0]);
  // 		// dispatch(saveSubsidiaryInfo(data?.data));
  // 	} catch (error: any) {
  // 		if (error?.response?.data?.message === 'Please login again') {
  // 			sessionStorage.clear();
  // 			window.location.href = '/';
  // 		} else {
  // 			const message = error?.response?.data?.message;

  // 			dispatch(
  // 				openToastAndSetContent({
  // 					toastContent: message,
  // 					toastStyles: ToastErrorStyles,
  // 				})
  // 			);
  // 		}
  // 	}
  // };
  interface initTypes {
    businessName: string;
    businessType: string;
    supportEmail: string;
    description: string;
    legalBusinessName: string;
    name: string;
    newPassword: string;
    password: string;
    phone: string;
    subsidiaryLogo: string;
    avatar: any;
  }

  const initProps: initTypes = {
    businessName: activeSubsidiary?.businessName,
    businessType: activeSubsidiary?.businessType,
    supportEmail: activeSubsidiary?.supportEmail,
    description: activeSubsidiary?.description,
    phone: activeSubsidiary?.mobileNumber,
    legalBusinessName: activeSubsidiary?.legalBusinessName,
    name: activeSubsidiary?.name,
    newPassword: '',
    avatar: '',
    password: '',
    subsidiaryLogo: activeSubsidiary?.subsidiaryLogo,
  };

  const [inputs, setInputs] = React.useState(initProps);

  useEffect(() => {
    // getMe();
  }, []);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const updateImageProps = async (event: any) => {
    dispatch(openLoader());
    let formData = new FormData();
    formData.append('logo', event?.target?.files[0]);
    try {
      const data: any = await formClient.post(
        '/subsidiary/dashboard/business/logos/add-or-update',
        formData
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const updateBusinessPayload = {
    businessName: inputs?.businessName,
    businessEmail: inputs?.supportEmail,
    description: inputs?.description,
    businessType: inputs?.businessType,
    legalBusinessName: inputs?.legalBusinessName,
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post('/subsidiary/dashboard/business/update', updateBusinessPayload)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const handleAvatarRemove = async () => {
    dispatch(openLoader());
    try {
      const data: any = await formClient.post(
        '/subsidiary/dashboard/business/logos/add-or-update',
        ''
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const changePassword = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        '/subsidiary/dashboard/change-password',
        {
          password: btoa(inputs?.password),
          newPassword: btoa(inputs?.newPassword),
        }
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  return (
    <div className={styles.business}>
      <div className={styles.info_wrapper}>
        <div className={styles.info_word}>
          <div className={styles.business_status}>
            <h2>Business Information</h2>
            <StatusView
              status={activeSubsidiary.status}
              orange="Pending-Approval"
              green="Active"
              indigo="New"
            />
          </div>
          <p>Enter your business information and ensure they are correct</p>
        </div>
        <div className={styles.info_data}>
          <div className={styles.user_description}>
            <div className={styles.user_description_image}>
              {activeSubsidiary?.subsidiaryLogo ? (
                <img
                  className={styles.user_description_image_main}
                  src={activeSubsidiary?.subsidiaryLogo}
                  alt=""
                />
              ) : (
                <img
                  className={styles.user_description_image_main}
                  src={'data:image/jpeg;base64,' + inputs.subsidiaryLogo}
                  alt=""
                />
              )}
            </div>

            <div className={styles.user_description_action}>
              <div className={styles.user_description_action_item}>
                <div className={styles.icon_box}>
                  <LoopIcon />
                </div>
                <div className={styles.actions}>
                  <form>
                    <label
                      style={{ cursor: 'pointer' }}
                      onClick={() => ref?.current?.click()}
                    >
                      <h4 className={styles.actions_h4}>Update</h4>
                    </label>
                    <input
                      type="file"
                      name="avatar"
                      id="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={updateImageProps}
                      ref={ref}
                      hidden
                    />
                  </form>
                </div>
              </div>
              <div className={styles.line}></div>
              <div
                onClick={handleAvatarRemove}
                className={styles.user_description_action_item}
              >
                <div className="icon_box">
                  <HighlightOffIcon />
                </div>
                <h4 className={styles.actions_h4}>Remove</h4>
              </div>
            </div>
          </div>
          <div className={styles.businessCard} style={{ marginTop: '35px' }}>
            <div className={styles.businessForm}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="businessName"
                  inputLabel="Business name"
                  disabled
                  inputValue={inputs?.businessName}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="supportEmail"
                  inputLabel="Business email"
                  disabled
                  inputValue={inputs?.supportEmail}
                />

                <OutlineTextInput
                  inputName="businessType"
                  inputLabel="Business type"
                  disabled
                  inputValue={inputs?.businessType}
                  inputSelect
                  inputOption={['Individual', 'Company']}
                />

                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="legalBusinessName"
                  disabled
                  inputLabel="Legal business name"
                  inputValue={inputs?.legalBusinessName}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="description"
                  disabled
                  inputLabel="Description"
                  multiline
                  inputValue={inputs?.description}
                />
              </form>

              <Divider style={{ marginTop: '64px' }} />
              {/* <div className={styles.buttonSubmit}>
								<div className={styles.uploadButton} onClick={handleSubmit}>
									Cancel
								</div>
								<div className={styles.uploadButton} onClick={handleSubmit}>
									Save Changes
								</div>
							</div> */}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '20px' }} className={styles.info_wrapper}>
        <div className={styles.info_word}>
          <h2>Password</h2>
          <p>Enter your personal information and ensure they are correct</p>
        </div>
        <div className={styles.info_data}>
          <div className={styles.passwordForm}>
            <form>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="password"
                inputLabel="Current Password"
                inputType="password"
                inputValue={inputs.password}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="newPassword"
                inputLabel="New Password"
                inputType="password"
                inputValue={inputs.newPassword}
              />
            </form>
            <div style={{ marginTop: '20px' }} className={styles.buttonSubmit}>
              <div className={styles.uploadButton} onClick={changePassword}>
                Reset password
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
