import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
  updateFormProperties,
  validateEmail,
} from '../../../utils/commonHelper';
import OutlineTextInput from '../../input/OutlineTextInput';
import styles from './Forgotpassword.module.scss';

const ForgotPasswordModal = ({ closeModal }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  interface initTypes {
    identifier: string;
    identifierError?: string;
  }

  const initProps: initTypes = {
    identifier: '',
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    return validateEmail(inputs.identifier);
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cb = (pp: any) => setInputs({ ...inputs, ...pp });
    updateFormProperties(event, cb);
    // const newValue = event.target.value;
    // const inputName = event.target.name;
    // setInputs((prevState: initTypes) => {
    //   return {
    //     ...prevState,
    //     [inputName]: newValue,
    //   };
    // });
  };

  const createPaymentLink = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        '/subsidiary/dashboard/forgot-password',
        inputs
      );

      const message = data?.data?.message;
      history.push(`/forgot-password/${inputs.identifier}`);
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Reset password</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="identifier"
            inputLabel="Email Address"
            InputHelper={inputs.identifierError}
            inputValue={inputs.identifier}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Reset password
          </div>
        ) : (
          <div className={styles.notClickable}>Reset password</div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
