import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import OutlineTextInput from '../../input/OutlineTextInput';
import styles from './Role.module.scss';

const EditRole = ({
  closeModal,
  fetchPaymentLink,
  permissions,
  roleDetails,
  allPermissions,
}: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    name: string;
    description: string;
  }

  const initProps: initTypes = {
    name: roleDetails?.name,
    description: roleDetails?.description,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name && inputs.description && selectedId) {
      return true;
    }
  };

  let permissionOptions = permissions?.map(function (permission: any) {
    return { value: permission?.id, label: permission?.permission };
  });

  const [selectedOption, setSelectedOption] =
    React.useState<any>(permissionOptions);

  const selectedId = selectedOption?.map((item: any) => item.value);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  let allRolePermissions = allPermissions?.map(function (permission: any) {
    return { value: permission?.id, label: permission?.name };
  });

  const createPaymentLink = async () => {
    try {
      const data: any = await client.post('subsidiary/dashboard/role/edit', {
        ...inputs,
        permissions: selectedId,
        role_id: roleDetails?.id,
      });
      closeModal();
      const message = data?.data?.message;
      fetchPaymentLink();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      closeModal();

      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Edit role</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Role name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />
          <div>
            <label className={styles.inputLabel}>Permissions</label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={allRolePermissions}
              isMulti
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              // styles={customStyles}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        <div className={styles.deactivate} onClick={handleSubmit}>
          Save changes
        </div>
      </div>
    </div>
  );
};

export default EditRole;
