import { Dropdown, Menu } from 'antd';
import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import client from '../../api/client';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { changeNavbar } from '../../redux/actions/navbar/navbarActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import styles from './SideDraawer.module.scss';
// import { ReactComponent as DashboardIcon } from '../../assets/IconsMerchant/dashboard.svg';
// import { ReactComponent as TransactionIcon } from '../../assets/IconsMerchant/transactions.svg';
// import { ReactComponent as CustomerIcon } from '../../assets/IconsMerchant/customers.svg';
// import { ReactComponent as ProductIcon } from '../../assets/IconsMerchant/Products.svg';
// import { ReactComponent as PaymentIcon } from '../../assets/IconsMerchant/paymentLink.svg';
// import { ReactComponent as BalancesIcon } from '../../assets/IconsMerchant/balances.svg';
// import { ReactComponent as VirtualIcon } from '../../assets/IconsMerchant/virtualwallet.svg';
// import { ReactComponent as SettlementIcon } from '../../assets/IconsMerchant/settlements.svg';
// import { ReactComponent as ProfileIcon } from '../../assets/IconsMerchant/settingBusiness.svg';
// import { ReactComponent as PayoutAccountIcon } from '../../assets/IconsMerchant/settingsPayout.svg';
// import { ReactComponent as RolesIcon } from '../../assets/IconsMerchant/settingsroles.svg';
// import { ReactComponent as UserIcon } from '../../assets/IconsMerchant/settingsUsers.svg';
// import { ReactComponent as PreferencesIcon } from '../../assets/IconsMerchant/settingsPreference.svg';
// import { ReactComponent as AuditIcon } from '../../assets/IconsMerchant/settingsAudit.svg';
// import { ReactComponent as ApiIcon } from '../../assets/IconsMerchant/settingsApi.svg';
// import { ReactComponent as InvoiceIcon } from '../../assets/IconsMerchant/Invoives.svg';
// import { ReactComponent as BalanceHistoryIcon } from '../../assets/sidedrawericons/balancehistory.svg';
// import { ReactComponent as RollingReserveIcon } from '../../assets/sidedrawericons/rollingreserve.svg';
// import { ReactComponent as PendingApprovalIcon } from '../../assets/sidedrawericons/pendingapproval.svg';
// import { ReactComponent as RefundyIcon } from '../../assets/sidedrawericons/refund.svg';
// import { ReactComponent as AllChargeBackIcon } from '../../assets/sidedrawericons/allchargeback.svg';
// import { ReactComponent as PendingIcon } from '../../assets/sidedrawericons/pending.svg';
// import { ReactComponent as AwaitingResponseIcon } from '../../assets/sidedrawericons/awaitingresponse.svg';
// import { ReactComponent as WonIcon } from '../../assets/sidedrawericons/won.svg';
// import { ReactComponent as LostIcon } from '../../assets/sidedrawericons/lost.svg';
// import { ReactComponent as AssessmentIcon } from '../../assets/sidedrawericons/assessment.svg';
import { ArrowDropDown } from '@mui/icons-material';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/Logos/paymentgateway.png';
import { customStyles } from '../../constants/modalStyles';
import { SidebarRoutes } from '../../constants/sidebarRoutes';
import {
  saveSubsidiaryInfo,
  setActiveSubsidiaryAction,
} from '../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import CurrencyButton from '../CurrencyButton/CurrencyButton';
import AddNewSub from '../ModalContent/AddNewSub/AddNewSub';

const { SubMenu } = Menu;

interface orgTypes {
  id: number;
  organization: {
    id: number;
    name: string;
    logo: string;
  };
}

interface propType {
  collapse: boolean;
}

function truncateString(str: string, num: number) {
  if (str && str?.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const SideDrawer = ({ collapse }: propType) => {
  // const PGDrawerContent = [
  // 	{
  // 		id: 1,
  // 		name: 'Dashboard',
  // 		route: '/dashboard',
  // 		icon: <DashboardIcon />,
  // 	},
  // 	// {
  // 	// 	id: 2,
  // 	// 	name: 'Compliance',
  // 	// 	route: '/dashboard/compliance',
  // 	// 	icon: <ComplianceIcon />,
  // 	// },
  // 	{
  // 		id: 2,
  // 		name: 'Transactions',
  // 		route: '/dashboard/business/transactions',
  // 		icon: <TransactionIcon />,
  // 	},
  // 	{
  // 		id: 3,
  // 		name: 'Invoices',
  // 		route: '/dashboard/business/invoice',
  // 		icon: <InvoiceIcon />,
  // 	},
  // 	{
  // 		id: 4,
  // 		name: 'Customers',
  // 		route: '/dashboard/business/customers',
  // 		icon: <CustomerIcon />,
  // 	},
  // 	{
  // 		id: 5,
  // 		name: 'Products',
  // 		route: '/dashboard/business/products',
  // 		icon: <ProductIcon />,
  // 	},
  // 	{
  // 		id: 6,
  // 		name: 'Payment Links',
  // 		route: '/dashboard/business/payment-links',
  // 		icon: <PaymentIcon />,
  // 	},
  // 	// {
  // 	// 	id: 7,
  // 	// 	name: 'Refunds',
  // 	// 	route: '/dashboard/business/refunds',
  // 	// 	icon: <RefundIcon />,
  // 	// },

  // 	{
  // 		id: 7,
  // 		name: 'Virtual Account',
  // 		route: '/dashboard/account/virtual-wallets',
  // 		icon: <VirtualIcon />,
  // 	},
  // 	{
  // 		id: 8,
  // 		name: 'Settlements',
  // 		route: '/dashboard/account/settlements',
  // 		icon: <SettlementIcon />,
  // 	},
  // 	{
  // 		id: 9,
  // 		name: 'Balance',
  // 		route: '/dashboard/account/balances',
  // 		icon: <BalancesIcon />,
  // 	},
  // 	{
  // 		id: 10,
  // 		name: 'Balance History',
  // 		route: '/dashboard/balance/history',
  // 		icon: <BalanceHistoryIcon />,
  // 	},
  // 	{
  // 		id: 11,
  // 		name: 'Rolling Reserve',
  // 		route: '/dashboard/balance/rollingreserve',
  // 		icon: <RollingReserveIcon />,
  // 	},
  // 	{
  // 		id: 12,
  // 		name: 'Transfers',
  // 		route: '/dashboard/payout/transfer',
  // 		icon: <BalancesIcon />,
  // 	},
  // 	{
  // 		id: 13,
  // 		name: 'Pending approval',
  // 		route: '/dashboard/payout/pendingapproval',
  // 		icon: <PendingApprovalIcon />,
  // 	},
  // 	{
  // 		id: 14,
  // 		name: 'Beneficiaries',
  // 		route: '/dashboard/payout/beneficiaries',
  // 		icon: <UserIcon />,
  // 	},
  // 	{
  // 		id: 15,
  // 		name: 'Funding history',
  // 		route: '/dashboard/payout/fundinghistory',
  // 		icon: <BalanceHistoryIcon />,
  // 	},
  // 	{
  // 		id: 16,
  // 		name: 'Refunds',
  // 		route: '/dashboard/refunds',
  // 		icon: <RefundyIcon />,
  // 	},
  // 	{
  // 		id: 17,
  // 		name: 'All chargebacks',
  // 		route: '/dashboard/chargeback',
  // 		icon: <AllChargeBackIcon />,
  // 	},
  // 	{
  // 		id: 18,
  // 		name: 'Pending',
  // 		route: '/dashboard/chargeback/pending',
  // 		icon: <PendingIcon />,
  // 	},
  // 	{
  // 		id: 19,
  // 		name: 'Awaiting response',
  // 		route: '/dashboard/chargeback/awaitingresponse',
  // 		icon: <AwaitingResponseIcon />,
  // 	},
  // 	{
  // 		id: 20,
  // 		name: 'Won',
  // 		route: '/dashboard/chargeback/won',
  // 		icon: <WonIcon />,
  // 	},
  // 	{
  // 		id: 21,
  // 		name: 'Lost',
  // 		route: '/dashboard/chargeback/lost',
  // 		icon: <LostIcon />,
  // 	},
  // 	{
  // 		id: 22,
  // 		name: 'Assessment',
  // 		route: '/dashboard/chargeback/assessment',
  // 		icon: <AssessmentIcon />,
  // 	},
  // 	{
  // 		id: 23,
  // 		name: 'Business',
  // 		route: '/dashboard/settings/business',
  // 		icon: <ProfileIcon />,
  // 	},

  // 	{
  // 		id: 24,
  // 		name: 'Payout Accounts',
  // 		route: '/dashboard/settings/payout-accounts',
  // 		icon: <PayoutAccountIcon />,
  // 	},
  // 	{
  // 		id: 25,
  // 		name: 'Roles & Permissions',
  // 		route: '/dashboard/settings/roles-permissions',
  // 		icon: <RolesIcon />,
  // 	},
  // 	{
  // 		id: 26,
  // 		name: 'Subsidiaries',
  // 		route: '/dashboard/settings/subsidiaries',
  // 		icon: <UserIcon />,
  // 	},
  // 	{
  // 		id: 27,
  // 		name: 'Users',
  // 		route: '/dashboard/settings/users',
  // 		icon: <UserIcon />,
  // 	},
  // 	{
  // 		id: 28,
  // 		name: 'Preferences',
  // 		route: '/dashboard/settings/preference',
  // 		icon: <PreferencesIcon />,
  // 	},
  // 	{
  // 		id: 29,
  // 		name: 'Audit Trails',
  // 		route: '/dashboard/settings/audit-trails',
  // 		icon: <AuditIcon />,
  // 	},
  // 	{
  // 		id: 30,
  // 		name: 'API Keys & Webhooks',
  // 		route: '/dashboard/settings/api-webhooks',
  // 		icon: <ApiIcon />,
  // 	},
  // ];

  const { subsidiaryInfo, activeSubsidiary } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const { onBoardStatus, onBoardRoute } = useSelector(
    (state) => state.onboardLevelInfoReducer
  );
  const { onVerificationState } = useSelector(
    (state) => state.onboardingStateReducer
  );

  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const setSubsidiarySession = async (subsidiary: any) => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        '/subsidiary/dashboard/session/set-subsidiary',
        { subsidiaryId: subsidiary.id, setAsDefault: true }
      );
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      dispatch(setActiveSubsidiaryAction(subsidiary));
      window.location.reload();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? error.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const getMe = async () => {
    try {
      const data: any = await client.get('/subsidiary/dashboard/me');
      var subsidiary = data?.data?.activeSubsidiary ?? {};
      if (subsidiary) dispatch(setActiveSubsidiaryAction(subsidiary));
      dispatch(saveSubsidiaryInfo(data?.data));
    } catch (error: any) {
      if (error?.response?.data?.message === 'Please login again') {
        sessionStorage.clear();
        window.location.href = '/';
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const subsidiaryDetails = subsidiaryInfo?.subsidiaryDetails;

  const location = useLocation();

  const path = location.pathname;

  // useEffect(() => {
  // }, [])
  if (
    path.toLowerCase() === '/login' ||
    path.toLowerCase() === '/reset_password'
  ) {
    return <div></div>;
  }

  const menu = (
    <Menu
      items={subsidiaryDetails?.subsidiaries?.map((subsidiary: any) => {
        return {
          key: subsidiary.id,
          label: (
            <div
              key={subsidiary?.id}
              className={styles.newBusinesses}
              onClick={() => setSubsidiarySession(subsidiary)}
            >
              <div className={styles.businessName}>
                {subsidiary?.businessName}
              </div>
            </div>
          ),
        };
      })}
    />
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrappercover}>
          <div className={styles.dropwrapper}>
            <div className={styles.dropdown}>
              <img src={logo} alt="logo" className={styles.dropdownimg} />
              <span
                className={
                  collapse ? styles.dropdownspan : styles.dropdownspancoll
                }
              ></span>
              {/* <div className={styles.dropdownIcon}>
									<DownOutlined />
								</div> */}
            </div>
          </div>

          <div className="MainAppMenu" style={{ flex: 1 }}>
            <div style={{ margin: '20px 14px', cursor: 'pointer' }}>
              <Dropdown overlay={menu}>
                <div className={styles.subwrapper}>
                  <div className={styles.subwrappercontent}>
                    <h2>
                      {!!activeSubsidiary && activeSubsidiary?.businessName}
                    </h2>
                    {activeSubsidiary?.description && (
                      <>
                        {/* <p style={{ wordBreak: "break-all" }}>
														Description:{' '}
														{truncateString(
															activeSubsidiary?.subsidiaryDetails?.subsidiaries[0]
																?.description,
															10
														)}
													</p> */}
                        <p>Merchant ID: {activeSubsidiary?.id}</p>
                      </>
                    )}
                  </div>
                  <div className={styles.dropdownIcon}>
                    <ArrowDropDown />
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className={styles.changeCurrencyWrapper}>
              <CurrencyButton />
            </div>
            {onVerificationState == 'Active' ? (
              ''
            ) : onVerificationState !== 'Pending-Approval' && onBoardRoute ? (
              <div className={styles.getstarted}>
                <Link
                  onClick={() => {
                    dispatch(changeNavbar('OnBoarding'));
                  }}
                  className={styles.getstartedlink}
                  to="/dashboard/getStarted"
                >
                  OnBoarding
                </Link>
              </div>
            ) : onVerificationState !== 'Pending-Approval' && !onBoardRoute ? (
              <div className={styles.getstarted}>
                <Link
                  onClick={() => {
                    dispatch(changeNavbar('Get Started'));
                  }}
                  className={styles.getstartedlink}
                  to="/dashboard/getStarted/onboarding"
                >
                  CONTINUE KYC
                </Link>
              </div>
            ) : (
              ''
            )}

            {/* {!started && (
							<div className={styles.getstarted}>
								<Link
									onClick={liveHandler}
									className={styles.getstartedlink}
									to='/dashboard/getStarted'>
									GO LIVE
								</Link>
							</div>
						)} */}

            <Menu
              selectedKeys={[path]}
              // selectedKeys={[`${SidebarRoutes[0]?.id}`]}
              defaultOpenKeys={[`${SidebarRoutes[0]?.id}`]}
              mode="inline"
              // theme='dark'
              // style={{ background: '#ffffff', color: 'black' }}
            >
              {SidebarRoutes.map((menu) => (
                <SubMenu key={menu.id} title={menu.name}>
                  {menu.subRoutes.map((item) => (
                    <Menu.Item key={item.route}>
                      <NavLink
                        // onClick={() => {
                        // 	dispatch(changeNavbar(item.name));
                        // 	// dispatch(saveStarted(false));
                        // }}
                        to={item.route}
                        key={item.route}
                        data-for={item.route}
                        data-tip={item.name}
                        exact={true}
                      >
                        {item.icon}&nbsp;&nbsp;&nbsp;&nbsp;
                        {item.name}
                      </NavLink>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <AddNewSub closeModal={closeModal} />
        </Modal>
      </div>
    </>
  );
};
