import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import { customStyles } from '../../../constants/modalStyles';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import OutlineTextInput from '../../input/OutlineTextInput';
import PayoutVerificationModal from '../PayoutVerificationModal/PayoutVerificationModal';
import BeneficiaryPayout from './BeneficiaryPayout';
import styles from './Initiatepayout.module.scss';

const InitiatePayout = ({ closeModal, fn, beneficiary, fn2 }: any) => {
  const dispatch = useDispatch();
  const [banks, setBanks] = React.useState<any>([]);
  const [selectedBank, setSelectedBank] = React.useState<any>();
  const [selectedType, setSelectedType] = React.useState<any>({
    value: 'Bank',
    label: 'Bank',
  });
  const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);
  const { currencies, currency } = useSelector(
    (state) => state?.appSettingsReducer
  );
  const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>(
    {
      label: currency,
      value: currency,
    }
  );
  const [resolveData, setResolvedData] = React.useState<any>();
  const [active, setActive] = React.useState('new');

  const [modalIsOpen, setIsModalOpen] = React.useState({
    isOpened: false,
    data: null,
  });

  const Type = [
    {
      id: 1,
      name: 'Bank',
    },
    {
      id: 2,
      name: 'Merchant',
    },
    // {
    // 	id: 3,
    // 	name: 'Mobile',
    // },
  ];

  let selectedTypeOptions = Type?.map(function (ty: any) {
    return {
      value: ty.name,
      label: ty.name,
    };
  });
  const closePayoutVerificationModal = () => {
    setIsModalOpen({ isOpened: false, data: null });
    fn();
    fn2();
    closeModal();
  };

  const fetchBanks = async () => {
    try {
      const data: any = await client.get('/subsidiary/dashboard/banks');
      setBanks(data?.data?.banks);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handlePayoutResponse = async (cbData: any) => {
    dispatch(openLoader());
    try {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: cbData?.message ?? '',
        })
      );
      if (cbData.statusCode == '00') {
        fn();
        fn2();
        closeModal();
        return;
      } else if (cbData.statusCode == '007') {
        setIsModalOpen({ isOpened: true, data: cbData });
      } else {
        const { message } = cbData?.message || {
          message: 'Unable to initiate payout at the moment',
        };
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {
        message: 'Unable to get auth code, please try again',
      };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  let selectedBanksOptions = banks?.map(function (bank: any) {
    return {
      value: bank.id,
      label: bank.name,
      code: bank.bankCode,
    };
  });

  interface initTypes {
    accountNumber: string | undefined;
    description: string;
    amount: number | undefined;
  }

  const initProps: initTypes = {
    accountNumber: undefined,
    description: '',
    amount: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      inputs.accountNumber &&
      inputs.amount &&
      !!resolveData?.data?.accountName
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    if (inputName == 'accountNumber' && newValue?.length > 10) return;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  useEffect(() => {
    fetchBanks();
    // fetchCurrencies();
  }, []);

  useEffect(() => {
    const bene = currencies?.map((x: any) => ({
      value: x.shortName,
      label: `${x.shortName}`,
    }));
    if (bene && bene.length > 0) setCurrenciesOption([...bene]);
  }, [currencies]);

  const payloadBank = {
    bankCode: selectedBank?.code ?? selectedType?.value,
    bankId: selectedBank?.value,
    creditCurrency: selectedCurrencyInput.value,
    debitCurrency: selectedCurrencyInput.value,
    type: selectedType?.value,
    AccountName: resolveData?.data?.accountName,
    amount: inputs.amount,
    description: inputs.description,
    accountNumber: inputs.accountNumber,
  };

  const initiatePayout = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post('/subsidiary/payout/initiate', {
        payoutDetails: [payloadBank],
      });
      const message = data?.data?.message;
      handlePayoutResponse(data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const resolveAccount = async () => {
    if (
      !!inputs?.accountNumber &&
      selectedType?.value?.toLowerCase() == 'bank' &&
      inputs?.accountNumber?.length < 10
    )
      return;
    dispatch(openLoader());
    try {
      const data: any = await client.post('/subsidiary/beneficiary/resolve', {
        type: selectedType?.value,
        accountNumber: inputs?.accountNumber,
        bankCode: selectedBank?.code,
      });
      setResolvedData(data?.data);
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };
  useEffect(() => {
    if (inputs?.accountNumber && selectedBank?.code !== '') resolveAccount();
  }, [inputs?.accountNumber, selectedBank?.code]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    initiatePayout();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Transfer</div>
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.buttonSwitch}>
        <button
          onClick={() => setActive('new')}
          id={active === 'new' ? styles.buttonSwitchButton : ''}
          className={styles.buttonSwitchButton}
        >
          New Transfer
        </button>
        <button
          onClick={() => setActive('ben')}
          id={active === 'ben' ? styles.buttonSwitchButton : ''}
          className={styles.buttonSwitchButton}
        >
          Beneficiary
        </button>
      </div>
      {active === 'new' && (
        <>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="amount"
                inputLabel="Amount"
                inputValue={inputs.amount}
              />
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Select Currency</label>
                <Select
                  defaultValue={selectedCurrencyInput}
                  onChange={setSelectedCurrencyInput}
                  options={currenciesOption}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: '100%',
                    }),
                  }}
                />
              </div>
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Type</label>
                <Select
                  defaultValue={selectedType}
                  onChange={setSelectedType}
                  options={selectedTypeOptions}
                  // styles={customStyles}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: '100%',
                    }),
                  }}
                />
              </div>

              {selectedType?.value === 'Bank' && (
                <div className={styles.modalselect}>
                  <label className={styles.inputLabel}>Bank</label>
                  <Select
                    defaultValue={selectedBank}
                    onChange={setSelectedBank}
                    options={selectedBanksOptions}
                    className={styles.select}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                        width: '100%',
                      }),
                    }}
                  />
                </div>
              )}
              {selectedType?.value === 'Mobile' && (
                <OutlineTextInput
                  maxLength={10}
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Mobile Number"
                  inputValue={inputs.accountNumber}
                />
              )}
              {selectedType?.value === 'Merchant' && (
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Merchant ID"
                  inputType="number"
                  inputValue={inputs.accountNumber}
                />
              )}
              {selectedType?.value === 'Bank' && (
                <OutlineTextInput
                  inputType="number"
                  handleChange={updateProps}
                  inputName="accountNumber"
                  inputLabel="Account number"
                  inputValue={inputs.accountNumber}
                />
              )}

              {resolveData && (
                <p>
                  Account Name:{' '}
                  <span style={{ color: 'green' }}>
                    {resolveData?.data?.accountName}
                  </span>{' '}
                </p>
              )}
              <OutlineTextInput
                handleChange={updateProps}
                inputName="description"
                inputLabel="Description"
                inputValue={inputs.description}
                // multiline={true}
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Initiate payout
              </div>
            ) : (
              <div className={styles.notClickable}> Initiate payout</div>
            )}
          </div>
        </>
      )}
      {active === 'ben' && (
        <BeneficiaryPayout
          payoutCb={handlePayoutResponse}
          fn={fn}
          closeModal={closeModal}
          beneficiary={beneficiary}
        />
      )}
      <Modal
        isOpen={modalIsOpen.isOpened}
        onRequestClose={closeModal}
        contentLabel="Verify Payout"
        style={customStyles}
      >
        <PayoutVerificationModal
          data={modalIsOpen.data}
          closeModal={closePayoutVerificationModal}
        />
      </Modal>
    </div>
  );
};

export default InitiatePayout;
