import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import client from '../../api/client';
import FilteredUsage from '../../components/filterUsableComponent/FilteredUsage';
import BalanceMenu from '../../components/MenuOption/BalanceMenu/BalanceMenu';
import CreatePaymentLink from '../../components/ModalContent/CreatePaymentLink/CreatePaymentLink';
import StatusView from '../../components/StatusView/StatusView';
import PaginationTable from '../../components/table/pagination-table';
import TableHeader from '../../components/TableHeader/TableHeader';
import { customStyles } from '../../constants/modalStyles';
import { ToastErrorStyles } from '../../constants/toastStyles';
import {
  closeLoader,
  openLoader,
} from '../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
  BalanceHistoryData,
  ColumnBalanceHistory,
} from '../../types/TablesTypes/balanceHistory';
import { numberWithCommas } from '../../utils/formatNumber';
import styles from './Balancehistory.module.scss';

const BalanceHistory = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const { id } = useParams<{ id: any }>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [direction, setDirection] = useState<string>('');
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.appSettingsReducer);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState('');
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate('');
    setToDate('');
    setReference('');
    setStatus('');
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: 'Direction',
      value: status,
      setValue: setStatus,
      selective: [
        { name: 'Debit', id: 'D' },
        { name: 'Credit', id: 'C' },
      ],
    },
    {
      id: 2,
      name: 'Reference',
      value: reference,
      setValue: setReference,
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  const fetchBalanceHistory = () => {
    dispatch(openLoader());
    client
      .get(
        `/subsidiary/dashboard/fetch/wallet/history?currency=${currency}&direction=${status}&reference=${reference}&fromdate=${fromDate}&todate=${toDate}`
      )
      .then((res: any) => {
        setCustomerDetails(res.data);
        dispatch(closeLoader());
        setBearer(false);
      })
      .catch((error) => {
        dispatch(closeLoader());
        setBearer(false);

        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
        } else if (error?.response?.data?.message === 'Please login again') {
          sessionStorage.clear();
          window.location.href = '/';
        } else {
        }
      });
  };
  useEffect(() => {
    fetchBalanceHistory();
  }, [bearer, currency]);

  const dataPaymentLinks = () => {
    const tempArr: BalanceHistoryData[] = [];
    customerDetails?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((balance: any, index: number) => {
        tempArr.push({
          date: moment(balance?.dateCreated).format('MMMM Do YYYY, h:mm a'),
          amount: `${currency} ${numberWithCommas(balance?.amount)}`,
          direction: (
            <>
              <StatusView
                status={balance?.direction}
                green={'Credit'}
                red={'Debit'}
              />
            </>
          ),
          balance: `${currency} ${numberWithCommas(balance?.balance)}`,
          details: balance?.remarks,
          action: <BalanceMenu data={balance} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Balance History"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.totalItems}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreatePaymentLink
          closeModal={closeModal}
          fetchPaymentLink={fetchBalanceHistory}
        />
      </Modal>
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnBalanceHistory ? ColumnBalanceHistory : []}
          emptyPlaceHolder="You currently do not have any balance history"
          total={customerDetails?.data?.totalItems}
        />
      )}
    </Fragment>
  );
};

export default BalanceHistory;
