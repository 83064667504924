import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';
import {
  closeLoader,
  openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import styles from './Terms.module.scss';

function Terms() {
  const history = useHistory();
  const { terms_and_condition } = useSelector(
    (state) => state.onboardLevelInfoReducer.onboardLevelInfo.data
  );
  const [checked, setChecked] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };
  const dispatch = useDispatch();

  const submitHandler = (identifier: boolean) => {
    dispatch(openLoader());
    client
      .post(`/subsidiary/dashboard/onboarding/accept/terms`, {
        accepted: identifier,
      })
      .then(async (res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        await getOnboardingPercentagesAction(dispatch);
        history.push('/dashboard/getStarted/onboarding');
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div
          onClick={() => history.push('/dashboard/getStarted/onboarding')}
          className={styles.wrapperInnerLeft}
        >
          <img src={arrow} alt="" />
          <p>Get started</p>
        </div>
        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>Terms and Conditions</h2>
          <p className={styles.wrapperp}>
            These are all the terms of use and conditions surrounding the
            Payinvert payment solution. Kindly reach through and accept below.
          </p>
          <div className={styles.inputwrapper}>
            <section className={styles.wrapperInnerRightContent}>
              <div>
                <h3>Terms and Conditions for Business</h3>
                <p>
                  By using this Portal, any of our Portals and/or services, you
                  agree to these Terms of Use. The Portal Privacy Policy,
                  Acceptable Use Policy and Merchant Terms of Service (where
                  applicable) are incorporated by reference into these Terms of
                  Use
                </p>
              </div>
              <div>
                <h3>About Us</h3>
                <p>
                  Payinvert Portal is an online gateway that makes it easy and
                  convenient for merchants to register their business and
                  receive or disburse payments using our Payment Gateway. We
                  reserve the right to make changes to this privacy policy at
                  any time by giving notice to Users on this page.
                </p>
                <br />
                <p>
                  We advise that you check this page often referring to the date
                  of the last modification on the page. If a User objects to any
                  of the changes to the Policy, the User must cease using this
                  Site, or terminate account in the event that an account has
                  been created.
                </p>
              </div>
              <div>
                <h3>Age Restriction</h3>
                <p>
                  Our Portal and services are not directed to children under 18.
                  We do not knowingly collect information from children under
                  18. If as a parent or guardian, you become aware that your
                  child or ward has provided us with any information without
                  your consent, please contact us through details on this Term
                  of Use
                </p>
              </div>
              <div>
                <h3>Privacy Policy</h3>
                <p>
                  Payinvert is committed to managing your Personal Information
                  in line with global industry best practices. You can read our
                  Privacy Policy to understand how we use your information and
                  the steps we take to protect your information
                </p>
              </div>
              <div>
                <h3>Disputes & Reversal</h3>
                <p>
                  If you believe that an unauthorized or otherwise problematic
                  transaction has taken place, you agree to notify us
                  immediately, to enable us take action to help prevent
                  financial loss.All claims against us related to payments
                  should be made within 48 (forty-eight) hours after the date of
                  such payment. It will be taken that you waive all claims
                  against us, to the fullest extent of the law after the said
                  period of time.
                </p>
                <p>
                  If you enter into a transaction with a third party and have a
                  dispute over the goods or services they purchase, we have no
                  liability for such goods or services. Our only involvement
                  with regard to such transaction is as a pre-order gateway
                </p>
                <p>
                  In the event of an erroneous payment we may attempt to secure
                  the funds from your bank and reverse the funds into your
                  account less a reversal fee but have no obligation to do so
                </p>
                <p>
                  Your transaction ID and/or transaction details will be
                  required to resolve all disputes.
                </p>
              </div>
              <div>
                <h3>Acceptable Use Policy</h3>
                <p>
                  You are independently responsible for complying with all
                  applicable laws related to your use of our Portal and
                  services. However, by accessing or using Payinvert, you agree
                  to comply with the terms and conditions of our Acceptable Use
                  Policy which you can read on our Acceptable Use Policy page.
                </p>
              </div>
              <div>
                <h3>Disclaimers</h3>
                <p>
                  WE TRY TO KEEP Payinvert AVAILABLE AT ALL TIMES, BUG-FREE AND
                  SAFE, HOWEVER, YOU USE IT AT YOUR OWN RISK.
                </p>
                <p>
                  OUR PORTAL AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY
                  EXPRESS, IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT
                  NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
                  TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS).
                  WITHOUT LIMITING THE GENERALITY OF THE
                  FOREGOING, Payinvert MAKES NO WARRANTY THAT OUR PORTAL AND
                  SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR PORTAL WILL
                  BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. NO ADVICE OR
                  INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH
                  OUR PORTAL OR FROM Payinvert, ITS PARENTS, SUBSIDIARIES, OR
                  OTHER AFFILIATED COMPANIES, OR ITS OR THEIR SUPPLIERS (OR THE
                  RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF ANY
                  SUCH ENTITIES) (COLLECTIVELY, "Payinvert PARTIES") SHALL
                  CREATE ANY WARRANTY.
                </p>
              </div>
              <div>
                <h3>Limitation of Liability</h3>
                <p>
                  YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM
                  EXTENT PERMITTED BY APPLICABLE LAW: Payinvert WILL IN NO WAY
                  BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE,
                  CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES
                  INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS,
                  USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER
                  INTANGIBLE LOSSES (WHETHER Payinvert HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES OR NOT) (INCLUDING, WITHOUT
                  LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RUSULT OF
                  USE OF Payinvert’S PORTAL OR SERVICES) WHETHER SUCH DAMAGES
                  ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER
                  LEGAL THEORY
                </p>
              </div>
              <div>
                <h3>Exclusions</h3>
                <p>
                  Some jurisdictions do not allow the exclusion of certain
                  warranties or the limitation or exclusion of liability for
                  certain damages. Accordingly, some of the above disclaimers
                  and limitations of liability may not apply to you. To the
                  extent that any Payinvert Party may not, as a matter of
                  applicable law, disclaim any implied warranty or limit its
                  liabilities, the scope and duration of such warranty and the
                  extent of the Payinvert’s Party's liability shall be the
                  minimum permitted under such applicable law.
                </p>
              </div>
              <div>
                <h3>Updates, Modifications & Amendments</h3>
                <p>
                  We may need to update, modify or amend our Terms of Use as our
                  technology evolves. We reserve the right to make changes to
                  this Terms of Use at any time by giving notice to users on
                  this page.
                </p>
                <p>
                  We advise that you check this page often, referring to the
                  date of the last modification on the page If a user objects to
                  any of the changes to the Terms of Use, the User must cease
                  using our Portal and/or services immediately.
                </p>
              </div>
              <div>
                <h3>Applicable Law</h3>
                <p>
                  These Terms of Use shall be interpreted and governed by the
                  laws currently in force in the Federal Republic of Nigeria.
                </p>
              </div>
              <div>
                <h3>Legal Disputes</h3>
                <p>
                  We shall make an effort to settle all disputes amicably. Any
                  dispute arising out of this Terms of Use, which cannot be
                  settled, by mutual agreement/negotiation within 1 (one) month
                  shall be referred to arbitration by a single arbitrator at the
                  Lagos Multi-Door Courthouse (“LMDC”) and governed by the
                  Arbitration and Conciliation Act, Cap A10, Laws of the Federal
                  Republic of Nigeria. The arbitrator shall be appointed by both
                  of us (we and you), where both of us are unable to agree on
                  the choice of an arbitrator, the choice of arbitration shall
                  be referred to the LMDC.  The findings of the arbitrator and
                  subsequent award shall be binding on both of us. Each of us
                  shall bear our respective costs in connection with the
                  Arbitration. Venue for the arbitration shall be Lagos,
                  Nigeria.
                </p>
              </div>
              <div>
                <h3>Severability</h3>
                <p>
                  If any portion of these Terms of Use is held by any court or
                  tribunal to be invalid or unenforceable, either in whole or in
                  part, then that part shall be severed from these Terms of Use
                  and shall not affect the validity or enforceability of any
                  other part in this Terms of Use.
                </p>
              </div>
              <div>
                <h3>TERMS OF SERVICE</h3>
                <p>
                  By signing up for an account on this Portal, any of our
                  Portals and/or services, you are deemed a merchant and agree
                  to these Merchant Terms of Service (the “Agreement”).
                </p>
                <p>
                  PLEASE READ THESE MERCHANT TERMS OF SERVICE CAREFULLY BEFORE
                  SIGNING UP AS A MERCHANT. If you do not agree to any or all of
                  these Terms of Service, DO NOT USE THIS SITE!
                </p>
              </div>
              <div>
                <h3>AGREEMENT</h3>
                <p>
                  These Merchant Terms of Service is an agreement between you
                  and Payinvert. It details Payinvert’s obligations to you. It
                  also highlights certain risks on using the services and you
                  must consider such risks carefully as you will be bound by the
                  provision of this Agreement through your use of this Portal or
                  any of our services.
                </p>
              </div>
              <div>
                <h3>Registration</h3>
                <p>
                  To use the Payinvert portal, you have to create an Payinvert
                  account by registering. To register, you will provide us with
                  certain information such as your email, first name, last name,
                  business name and phone number and we may seek to verify your
                  information, (by ourselves or through third parties), after
                  which we will approve your account unless deemed risky. You
                  give us permission to do all these.
                </p>
              </div>
              <div>
                <h3>Change of Information</h3>
                <p>
                  In the event that you change any information provided to us at
                  registration including your business name, address, financial
                  institution, mode of payments or the products and services
                  that you offer, or where a corporate restructuring occurs you
                  agree to notify us within 14 days of such change.  We may be
                  unable to respond to you if you contact us from an address,
                  telephone number or email account that is not registered with
                  us.
                </p>
              </div>
              <div>
                <h3>Representation and Warranties</h3>
                <p>You represent and warrant to Payinvert that:</p>
                <ol>
                  <li>
                    you have full power and authority to enter into, execute,
                    deliver and perform this Agreement;
                  </li>
                  <li>
                    you are duly organised, authorised and in good standing
                    under the laws of the Federal Republic of Nigeria or any
                    state, region or country of your organisation and are duly
                    authorised to do business in all other states, regions or
                    countries in which your business operates
                  </li>
                </ol>
              </div>
              <div>
                <h3>Account Security</h3>
                <p>
                  You agree not to allow anyone else to have or use your
                  password details and to comply with all reasonable
                  instructions we may issue regarding account access and
                  security. In the event you share your password details,
                  Payinvert will not be liable to you for losses or damages. You
                  will also take all reasonable steps to protect the security of
                  the personal electronic device through which you access
                  Payinvert’s services (including, without limitation, using PIN
                  and/or password protected personally configured device
                  functionality to access Payinvert’s services and not sharing
                  your device with other people).
                </p>
              </div>
              <div>
                <h3>Data Compliance</h3>
                <p>
                  You agree to comply with all data privacy and security
                  requirements of the Payment Card Industry Data Security
                  Standard (PCI DSS Requirements”) and under any applicable law
                  or regulation that may be in force, enacted or adopted
                  regarding confidentiality, your access, use, storage and
                  disclosure of user information. Information on the PCI DSS can
                  be found on the PCI Council’s Portal. It is your
                  responsibility to comply with these standards
                </p>
                <p>
                  We are responsible for the security and protection of Card
                  Holder Data (CHD) we collect and store. Accordingly, we
                  implement access control measures, security protocols and
                  standards including the use of encryption and firewall
                  technologies to ensure that CHD is kept safe and secure on our
                  servers, in compliance with the PCI DSS Requirement. We also
                  implement periodical security updates to ensure that our
                  security infrastructures are in compliance with reasonable
                  industry standards.
                </p>
                <p>
                  We acknowledge that you own all your customers’ data. You
                  hereby grant Payinvert a perpetual, irrevocable,
                  sub-licensable, assignable, worldwide, royalty-free license to
                  use, reproduce, electronically distribute, and display your
                  customers’ data for the following purposes:
                </p>
                <ol>
                  <li>providing and improving our services;</li>
                  <li>
                    internal usage, including but not limited to, data analytics
                    and metrics so long as individual customer data has been
                    anonymized and aggregated with other customer data;
                  </li>
                  <li>
                    complying with applicable legal requirements and assisting
                    law enforcement agencies by responding to requests for the
                    disclosure of information in accordance with local laws; and
                  </li>
                  <li>
                    any other purpose for which consent has been provided by
                    your customer
                  </li>
                </ol>
              </div>
              <div>
                <h3>Software License</h3>
                <p>
                  We hereby grant you a revocable, non-exclusive,
                  non-transferable license to use Payinvert’s APIs, developer’s
                  toolkit, and other software applications (the “Software”) in
                  accordance with the documentation accompanying the Software.
                  This license grant includes all updates, upgrades, new
                  versions and replacement software for your use in connection
                  with the Payinvert’s services. If you do not comply with the
                  documentation and any other requirements provided by
                  Payinvert, then you will be liable for all resulting damages
                  suffered by you, Payinvert and third parties. Unless otherwise
                  provided by applicable law, you agree not to alter, reproduce,
                  adapt, distribute, display, publish, reverse engineer,
                  translate, disassemble, decompile or otherwise attempt to
                  create any source code that is derived from the Software. Upon
                  expiration or termination of this Agreement, you will
                  immediately cease all use of any Software.
                </p>
              </div>
              <div>
                <h3>Trademark License</h3>
                <p>
                  We hereby grant you a revocable, non-exclusive,
                  non-transferable license to use Payinvert’s trademarks used to
                  identify our services (the “Trademarks”) solely in conjunction
                  with the use of our services. You agree that you will not at
                  any time during or after this Agreement assert or claim any
                  interest in or do anything that may adversely affect the
                  validity of any Trademark or any other trademark, trade name
                  or product designation belonging to or licensed to Payinvert
                  (including, without limitation registering or attempting to
                  register any Trademark or any such other trademark, trade name
                  or product designation). Upon expiration or termination of
                  this Agreement, you will immediately cease all display,
                  advertising and use of all of the Trademarks.
                </p>
              </div>
              <div>
                <h3>Intellectual Property</h3>
                <p>
                  We do not grant any right or license to any Payinvert
                  intellectual property rights by implication, estoppel or
                  otherwise other than those expressly mentioned in this
                  Agreement. Each party shall retain all intellectual property
                  rights including all ownership rights, title, and interest in
                  and to its own products and services, subject only to the
                  rights and licenses specifically granted herein.
                </p>
              </div>
              <div>
                <h3>Publicity</h3>
                <p>
                  You hereby grant Payinvert permissions to use your name and
                  logo in our marketing materials including, but not limited to
                  use on our Portal, in customer listings, in interviews and in
                  press releases. Such publicity does not imply an endorsement
                  for your products and services.
                </p>
              </div>
              <div>
                <h3>Confidential Information</h3>
                <p>
                  The parties acknowledge that in the performance of their
                  duties under this Agreement, either party may communicate to
                  the other (or its designees) certain confidential and
                  proprietary information, including without limitation
                  information concerning each party’s services, know how,
                  technology, techniques, or business or marketing plans
                  (collectively, the “Confidential Information”) all of which
                  are confidential and proprietary to, and trade secrets of, the
                  disclosing party. Confidential Information does not include
                  information that: (i) is public knowledge at the time of
                  disclosure by the disclosing party; (ii) becomes public
                  knowledge or known to the receiving party after disclosure by
                  the disclosing party other than by breach of the receiving
                  party’s obligations under this section or by breach of a third
                  party’s confidentiality obligations; (iii) was known by the
                  receiving party prior to disclosure by the disclosing party
                  other than by breach of a third party’s confidentiality
                  obligations; or (iv) is independently developed by the
                  receiving party.
                </p>
                <p>
                  As a condition to the receipt of the Confidential Information
                  from the disclosing party, the receiving party shall: (i) not
                  disclose in any manner, directly or indirectly, to any third
                  party any portion of the disclosing party’s Confidential
                  Information; (ii) not use the disclosing party’s Confidential
                  Information in any fashion except to perform its duties under
                  this Agreement or with the disclosing party’s express prior
                  written consent; (iii) disclose the disclosing party’s
                  Confidential Information, in whole or in part, only to
                  employees and agents who need to have access thereto for the
                  receiving party’s internal business purposes; (iv) take all
                  necessary steps to ensure that its employees and agents are
                  informed of and comply with the confidentiality restrictions
                  contained in this Agreement; and (v) take all necessary
                  precautions to protect the confidentiality of the Confidential
                  Information received hereunder and exercise at least the same
                  degree of care in safeguarding the Confidential Information as
                  it would with its own confidential information, and in no
                  event shall apply less than a reasonable standard of care to
                  prevent disclosure
                </p>
              </div>
              <div>
                <h3>Know Your Customer</h3>
                <p>
                  You agree that, you are solely responsible for verifying the
                  identities of your customers, ensuring that they are
                  authorised to carry out the transactions on your platform, and
                  determining their eligibility to purchase your products and
                  services.You are also required to maintain information and
                  proof of service or product delivery to your customer. Where a
                  dispute occurs needing resolution, you may be required to
                  provide Payinvert with these.
                </p>
              </div>
              <div>
                <h3>Card Network Rules</h3>
                <p>
                  Each card network has its own rules, regulations and
                  guidelines. You are required to comply with all applicable
                  Network Rules that are applicable to merchants. You can review
                  portions of the Network Rules at Mastercard, Visa, Verve and
                  other payment cards. The Card Networks reserve the right to
                  amend the Network Rules.
                </p>
              </div>
              <div>
                <h3>Customer Payments</h3>
                <p>
                  You may only process payments when authorised to do so by your
                  customer. We will only process transactions that have been
                  authorised by the applicable Card Network or card issuer.
                </p>
                <p>
                  We do not guarantee or assume any liability for transactions
                  authorised and completed that are later reversed or charged
                  back (see Chargebacks below). You are solely responsible for
                  all reversed or charged back transactions, regardless of the
                  reason for, or timing of, the reversal or chargeback.
                  Payinvert may add or remove one or more payment types or
                  networks at any time. If we do so we will use reasonable
                  efforts to give you prior notice of the removal.
                </p>
              </div>
              <div>
                <h3>Our Fees & Pricing Schedule</h3>
                <p>
                  You agree to pay us for the services we render as a gateway
                  for merchant registration, receipt and disbursements and
                  similar services. Our Fees will be calculated as demonstrated
                  on the Pricing page on the Portal and can be calculated on the
                  same page. The Fees on our Pricing page is integral to and
                  forms part of this Agreement. We reserve the right to revise
                  our Fees.
                </p>
                <p>
                  In the event that we revise our fees we will notify you within
                  5 days of such change.
                </p>
              </div>
              <div>
                <h3>Payouts</h3>
                <p>
                  Subject to the terms of this Agreement, Payinvert will send to
                  your designated bank or card settlement account (“Bank
                  Account”) all amounts settled and due to you from your
                  transactions, minus our fees as stated in the Fee Schedule,
                  any Reversals, Invalidated Payments, Chargebacks, Refunds or
                  other amounts that you owe to Payinvert under this Agreement
                  (“Payout”). If the Payout is not sufficient to cover the
                  amounts due, you agree that we may debit your Bank Account for
                  the applicable amounts, and/or set-off the applicable amounts
                  against future Payouts. Upon our request, you agree to provide
                  us with all necessary bank account and related information and
                  grant us permission to debit amounts due from your Bank
                  Account.
                </p>
                <p>
                  After transfer of funds is initiated to your Bank Account, we
                  will update information on your Payinvert Dashboard to reflect
                  settlement. Information regarding your transactions that are
                  processed and settled using Payinvert (“Transaction History”)
                  will be available to you when you login to your Payinvert
                  Dashboard. While we will provide Transaction History in your
                  Payinvert Dashboard, you are solely responsible for compiling
                  and retaining permanent records of all transactions and other
                  data associated with your Payinvert account as may be required
                  for your business.
                </p>
              </div>
              <div>
                <h3>Transaction History</h3>
                <p>
                  Payinvert shall be responsible for maintaining Transaction
                  History. Equally, Payinvert shall avail you the technical
                  opportunity and resources to view transactions in real time,
                  store and retrieve transaction history.
                </p>
              </div>
              <div>
                <h3>Payout Schedule</h3>
                <p>
                  Your Payout Schedule, which is the time it takes us to
                  initiate a transfer to your Bank Account settled funds from
                  card transactions processed through us is on your Payinvert
                  Dashboard. We reserve the right to change your Payout
                  Schedule, suspend payouts to your Bank Account or initiate a
                  Reversal should we deem it necessary due to pending disputes,
                  excessive or anticipated excessive Chargebacks or Refunds, or
                  other suspicious activity associated with your use of
                  Payinvert, or if required by law or court order.
                </p>
              </div>
              <div>
                <h3>How we handle your Funds</h3>
                <p>
                  You authorise and instruct Payinvert to hold, receive, and
                  disburse funds on your behalf when such funds from your card
                  transactions settle from the Card Networks. By accepting this
                  Agreement, you further authorise Payinvert on how your card
                  transaction settlement funds should be disbursed to you as
                  Payouts and the timing of such Payouts.
                </p>
                <p>
                  You agree that you are not entitled to any interest or other
                  compensation associated with the settlement funds held by
                  Payinvert pending settlement and Payout to your Bank Account.
                </p>
                <p>
                  Settlement funds will be held in a deposit account at
                  Payinvert pending Payouts to you in accordance with the terms
                  of this contract. We may periodically make available to you
                  information about pending settlements yet to be received from
                  the Card Networks.Your authorisations will remain valid and be
                  of full effect until your Payinvert Account is closed or
                  terminated.
                </p>
              </div>
              <div>
                <h3>Security and Fraud Controls</h3>
                <p>
                  Payinvert is responsible for protecting the security of
                  Payment Data including CHD in our possession and will maintain
                  commercially reasonable administrative, technical, and
                  physical procedures to protect all the personal information
                  regarding you and your customers that is stored in our servers
                  from unauthorised access and accidental loss or modification.
                  Although, we cannot guarantee that unauthorised third parties
                  will never be able to defeat those measures or use such
                  personal information for improper purposes. We will however
                  take all reasonable and commercially achievable measures to
                  address any security breach as soon as we become aware.
                </p>
                <p>
                  You agree to use other procedures and controls provided by us
                  and other measures that are appropriate for your business to
                  reduce the risk of fraud.
                </p>
                <p>
                  In the event that you suspect any fraudulent activity by a
                  customer, you agree to notify Payinvert immediately and quit
                  the delivery of the service. In addition, where we suspect
                  that there have been frequent fraudulent transactions on your
                  account, we reserve the right to cancel our service to you
                  and/or your account
                </p>
              </div>
              <div>
                <h3>Notification of Errors</h3>
                <p>
                  You agree to notify us immediately any error is detected while
                  reconciling transactions that have occurred using Payinvert.
                  We will investigate and rectify the errors where verified. In
                  the event that we notice any errors, we will also investigate
                  and rectify such errors.
                </p>
                <p>
                  Where we owe you money as a result of such errors, we will
                  refund the amounts owed to you by a bank transfer to your Bank
                  Account.
                </p>
                <p>
                  If a transaction is erroneously processed through your
                  platform, report to us immediately. We will investigate any
                  such reports and attempt to rectify the errors by crediting or
                  debiting your Bank Account as appropriate.
                </p>
                <p>
                  Failure to notify us within 45 (forty-five) days of the
                  occurrence of an error will be deemed a waiver of your rights
                  to amounts that are owed to you due to an error.
                </p>
              </div>
              <div>
                <h3>Chargebacks</h3>
                <p>
                  A Chargeback usually happens when a customer files directly
                  with or disputes through his or her credit or debit card
                  issuer a payment on their bill. It may result in the reversal
                  of a transaction. You may be assessed Chargebacks for (i)
                  customer disputes; (ii) unauthorised or improperly authorised
                  transactions; (iii) transactions that do not comply with Card
                  Network Rules or the terms of this Agreement or are allegedly
                  unlawful or suspicious; or (iv) any reversals for any reason
                  by the Card Network, our processor, or the acquiring or
                  issuing banks. Where a Chargeback occurs, you are immediately
                  liable for all claims, expenses, fines and liability we incur
                  arising out of that Chargeback and agree that we may recover
                  these amounts by debiting your Bank Account. Where these
                  amounts are not recoverable through your Bank Account, you
                  agree to pay all such amounts through any other means
                </p>
              </div>
              <div>
                <h3>Reserves</h3>
                <p>
                  In our sole discretion, we may place a Reserve on a portion of
                  your Payouts by holding for a certain period such portion
                  where we believe there is a high level of risk associated with
                  your business. If we take such steps, we will provide you with
                  the terms of the Reserve which may include the percentage of
                  your Payouts to be held back, period of time and any other
                  such restrictions that Payinvert may deem necessary. Where
                  such terms are changed, we will notify you. You agree that you
                  will remain liable for all obligations related to your
                  transactions even after the release of any Reserve. In
                  addition, we may require you to keep your Bank Account
                  available for any open settlements, Chargebacks and other
                  adjustments.
                </p>
                <p>
                  To secure your performance of this Agreement, you grant
                  Payinvert a legal claim to the funds held in the Reserve as a
                  lien or security interest for amounts payable by you.
                </p>
              </div>
              <div>
                <h3>Refunds</h3>
                <p>
                  You agree that you are solely responsible for accepting and
                  processing returns of your products and services. We are under
                  no obligation to process returns of your products and
                  services, or to respond to your customers’ inquiries about
                  returns of your products and services. You agree to submit all
                  Refunds for returns of your products and services that were
                  paid for through Payinvert to your customers in accordance
                  with this Agreement and relevant Card Network Rules.
                </p>
              </div>
              <div>
                <h3>Termination</h3>
                <p>
                  You may terminate this Agreement by closing your Payinvert
                  Account.
                </p>
                <p>
                  We may suspend your Payinvert Account and your access to
                  Payinvert services and any funds, or terminate this Agreement,
                  if;
                </p>
                <ol>
                  <li>
                    you do not comply with any of the provisions of this
                    Agreement;
                  </li>
                  <li>we are required to do so by a Law;</li>
                  <li>
                    we are directed by a Card Network or issuing financial
                    institution; or
                  </li>
                  <li>where a suspicious or fraudulent transaction occurs</li>
                </ol>
              </div>
              <div>
                <h3>Restricted Activities & Acceptable Use Policy</h3>
                <p>
                  You are independently responsible for complying with all
                  applicable laws related to your use of our Portal and
                  services. However, by accessing or using Payinvert, you agree
                  to comply with the terms and conditions of our Acceptable Use
                  Policy and are restricted from the activities specified in it
                  which you can read on our Acceptable Use Policy page.
                </p>
              </div>
              <div>
                <h3>Privacy Policy</h3>
                <p>
                  Payinvert is committed to managing your Personal Information
                  in line with global industry best practices. You can read our
                  Privacy Policy to understand how we use your information and
                  the steps we take to protect your information.
                </p>
              </div>
              <div>
                <h3>Disclaimers</h3>
                <p>
                  WE TRY TO KEEP Payinvert AVAILABLE AT ALL TIMES, BUG-FREE AND
                  SAFE, HOWEVER, YOU USE IT AT YOUR OWN RISK.
                </p>
                <p>
                  OUR PORTAL AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY
                  EXPRESS, IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT
                  NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
                  TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS).
                  WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Payinvert
                  MAKES NO WARRANTY THAT OUR PORTAL AND SERVICES WILL MEET YOUR
                  REQUIREMENTS OR THAT OUR PORTAL WILL BE UNINTERRUPTED, TIMELY,
                  SECURE, OR ERROR FREE. NO ADVICE OR INFORMATION, WHETHER ORAL
                  OR WRITTEN, OBTAINED BY YOU THROUGH OUR PORTAL OR FROM
                  Payinvert, ITS PARENTS, SUBSIDIARIES, OR OTHER AFFILIATED
                  COMPANIES, OR ITS OR THEIR SUPPLIERS (OR THE RESPECTIVE
                  OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF ANY SUCH
                  ENTITIES) (COLLECTIVELY, "Payinvert PARTIES") SHALL CREATE ANY
                  WARRANTY.
                </p>
              </div>
              <div>
                <h3>Limitation of Liability</h3>
                <p>
                  IN NO EVENT WILL ANY OF THE Payinvert PARTIES BE LIABLE FOR
                  (A) ANY INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR
                  EXEMPLARY DAMAGES OR (B) ANY DAMAGES WHATSOEVER IN EXCESS OF
                  THE AMOUNT OF THE TRANSACTION OR TWENTY THOUSAND UNITED STATES
                  DOLLARS (US$20,000.00) DOLLARS, WHICHEVER IS LESSER
                  (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF
                  REVENUES, LOST PROFITS, LOSS OF GOODWILL, LOSS OF USE,
                  BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES), ARISING
                  OUT OF OR IN CONNECTION WITH Payinvert’S PORTAL OR SERVICES
                  (INCLUDING, WITHOUT LIMITATION, USE, INABILITY TO USE, OR THE
                  RESULTS OF USE OF Payinvert’S PORTALS OR SERVICES), WHETHER
                  SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT, STATUTE,
                  OR ANY OTHER LEGAL THEORY.
                </p>
              </div>
              <div>
                <h3>Exclusions</h3>
                <p>
                  Some jurisdictions do not allow the exclusion of certain
                  warranties or the limitation or exclusion of liability for
                  certain damages.
                </p>
                <p>
                  Accordingly, some of the above disclaimers and limitations of
                  liability may not apply to you. To the extent that any
                  Payinvert Party may not, as a matter of applicable law,
                  disclaim any implied warranty or limit its liabilities, the
                  scope and duration of such warranty and the extent of the
                  Payinvert’s Party's liability shall be the minimum permitted
                  under such applicable law.
                </p>
              </div>
              <div>
                <h3>Indemnity</h3>
                <p>
                  You agree to defend, indemnify, and hold Payinvert, its
                  officers, directors, employees, agents, licensors, and
                  suppliers, harmless from and against any claims, actions or
                  demands, liabilities and settlements including without
                  limitation, reasonable legal and accounting fees, resulting
                  from, or alleged to result from, your violation of these
                  Agreement.
                </p>
              </div>
              <div>
                <h3>Updates, Modifications & Amendments</h3>
                <p>
                  We may need to update, modify or amend our Merchant Terms of
                  Service as our technology evolves. We reserve the right to
                  make changes to this Merchant Terms of Service at any time by
                  giving notice to users on this page.
                </p>
                <p>
                  We advise that you check this page often, referring to the
                  date of the last modification on the page. If you have any
                  objection to any of the changes to this Merchant Terms of
                  Service, you must cease using our Portal and/or services
                  immediately.
                </p>
              </div>
              <div>
                <h3>Applicable Law</h3>
                <p>
                  These Terms of Use shall be interpreted and governed by the
                  laws currently in force in the Federal Republic of Nigeria.
                </p>
              </div>
              <div>
                <h3>Legal Disputes</h3>
                <p>
                  We shall make an effort to settle all disputes amicably. Any
                  dispute arising out of this Agreement which cannot be settled,
                  by mutual agreement/negotiation within 1 (one) month shall be
                  referred to arbitration by a single arbitrator at the Lagos
                  Multi-Door Courthouse (“LMDC”) and governed by the Arbitration
                  and Conciliation Act, Cap A10, Laws of the Federal Republic of
                  Nigeria. The arbitrator shall be appointed by both of us (we
                  and you), where both of us are unable to agree on the choice
                  of an arbitrator, the choice of arbitration shall be referred
                  to the LMDC. The findings of the arbitrator and subsequent
                  award shall be binding on both of us. Each of us shall bear
                  our respective costs in connection with the Arbitration. Venue
                  for the arbitration shall be Lagos, Nigeria.
                </p>
              </div>
              <div>
                <h3>Severability</h3>
                <p>
                  If any portion of these Terms of Use is held by any court or
                  tribunal to be invalid or unenforceable, either in whole or in
                  part, then that part shall be severed from these Terms of Use
                  and shall not affect the validity or enforceability of any
                  other part in this Terms of Use.
                </p>
              </div>
              <div>
                <h3>Miscellaneous</h3>
                <p>
                  You agree that all agreements, notices, disclosures and other
                  communications that we provide to you electronically satisfy
                  any legal requirement that such communications be in writing.
                  Assigning or sub-contracting any of your rights or obligations
                  under these Terms of Use to any third party is prohibited. We
                  reserve the right to transfer, assign or sub-contract the
                  benefit of the whole or part of any rights or obligations
                  under these Terms of Use to any third party.
                </p>
              </div>
              <div>
                <h3>Acceptable Use Policy</h3>
                <p>
                  By accessing or using Payinvert’s Portal, you agree to comply
                  with the terms and conditions of this Acceptable Use Policy.
                </p>
              </div>
              <div>
                <h3>Restricted Activities</h3>
                <p>
                  You may not use Payinvert in connection with any product,
                  service, transaction or activity that: violates any law or
                  government regulation, or promotes or facilitates such by
                  third parties;
                </p>
                <ul>
                  <li>
                    violates any rule or regulation of Visa, MasterCard, Verve
                    or any other electronic funds transfer network (each, a
                    “Card Network”);
                  </li>
                  <li>is fraudulent, deceptive, unfair or predatory;</li>
                  <li>
                    causes or threatens reputational damage to us or any Card
                    Network;
                  </li>
                  <li>
                    involves any of the business categories listed in clause 2
                    below; or
                  </li>
                  <li>
                    results in or creates a significant risk of chargebacks,
                    penalties, damages or other harm or liability.
                  </li>
                </ul>
              </div>
              <div>
                <h3>Certain Business Categories</h3>
                <p>
                  You may not use Payinvert’s portal in connection with any
                  product, service, transaction or activity that:
                </p>
                <ol type="A">
                  <li>
                    falls within the Prohibition List of the Nigerian Customs
                    Administration of the Federal Republic of Nigeria.
                  </li>
                  <li>
                    Relates to the sale and/or purchase of:
                    <ol>
                      <li>
                        banned narcotics, steroids, certain controlled
                        substances or other products that present a risk a
                        consumer's safety;
                      </li>
                      <li>blood, bodily fluids or body parts;</li>
                      <li>burglary tools;</li>
                      <li>counterfeit items;</li>
                      <li>illegal drugs and drug paraphernalia;</li>
                      <li>fireworks, destructive devices and explosives;</li>
                      <li>
                        identity documents, government documents, personal
                        financial records or personal information (in any form,
                        including mailing lists);
                      </li>
                      <li>
                        lottery tickets, sweepstakes entries or slot machines
                        without the required license;
                      </li>
                      <li>
                        offensive material or hate speech or items that promote
                        hate, violence, racial intolerance, or the financial
                        exploitation of a crime;
                      </li>
                      <li>police badges or uniforms;</li>
                      <li>chemicals;</li>
                      <li>recalled items;</li>
                      <li>prohibited services;</li>
                      <li>
                        unlicensed financial services, stocks or other
                        securities;
                      </li>
                      <li>stolen property;</li>
                      <li>
                        items that infringe or violate any copyright, trademark,
                        right of publicity or privacy or any other proprietary
                        right under the laws of any jurisdiction;
                      </li>
                      <li>
                        sales of currency without Bureau De Change license,
                        certain cryptocurrency operators;
                      </li>
                      <li>obscene material or pornography;</li>
                      <li>certain sexually oriented materials or services;</li>
                      <li>
                        certain firearms, firearm parts or accessories,
                        ammunition, weapons or knives; or
                      </li>
                      <li>
                        any product or service that is illegal or marketed or
                        sold in such a way as to create liability to Payinvert.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Relates to transactions that:
                    <ol>
                      <li>
                        show the personal information of third parties in
                        violation of applicable law;
                      </li>
                      <li>
                        support pyramid or ponzi schemes, matrix programs, other
                        "get rich quick" schemes or certain multi-level
                        marketing programs;
                      </li>
                      <li>
                        are associated with purchases of annuities or lottery
                        contracts, lay-away systems, off-shore banking or
                        transactions to finance or refinance debts funded by a
                        credit card;
                      </li>
                      <li>pertain to ammunitions and arms; and</li>
                      <li>
                        involve gambling, gaming and/or any other activity with
                        an entry fee and a prize, including, but not limited to
                        casino games, sports betting, horse or greyhound racing,
                        lottery tickets, other ventures that facilitate
                        gambling, games of skill (whether or not it is legally
                        defined as a lottery) and sweepstakes unless the
                        operator has obtained prior approval from Payinvert and
                        the operator and customers are located exclusively in
                        jurisdictions where such activities are permitted by
                        law.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div>
                <h3>Actions by Payinvert</h3>
                <p>
                  If, in our sole discretion, we believe that you may have
                  engaged in any violation of this Acceptable Use Policy, we may
                  (with or without notice to you) take such actions as we deem
                  appropriate to mitigate risk to Payinvert and any impacted
                  third parties and to ensure compliance with this Acceptable
                  Use Policy. Such actions may include, without limitation:
                </p>
                <ol type="a">
                  <li>
                    Blocking the settlement or completion of one or more
                    payments;
                  </li>
                  <li>
                    Suspending, restricting or terminating your access to and
                    use of the Payinvert’s Services;
                  </li>
                  <li>
                    Terminating our business relationship with you, including
                    termination without liability to Payinvert of any payment
                    service agreement between you and Payinvert;
                  </li>
                  <li>Taking legal action against you;</li>
                  <li>
                    Contacting and disclosing information related to such
                    violations to (i) persons who have sold/purchased goods or
                    services from you, (ii) any banks or Card Networks involved
                    with your business or transactions, (iii) law enforcement or
                    regulatory agencies, and (iv) other third parties that may
                    have been impacted by such violations; or
                  </li>
                  <li>
                    Assessing against you any fees, penalties, assessments or
                    expenses (including reasonable attorneys’ fees) that we may
                    incur as a result of such violations, which you agree to pay
                    promptly upon notice.
                  </li>
                </ol>
              </div>
              <div>
                <h3>Updates, Modifications & Amendments</h3>
                <p>
                  We may need to update, modify or amend our Acceptable Use
                  Policy at any time. We reserve the right to make changes to
                  this Acceptable Use Policy.
                </p>
                <p>
                  We advise that you check this page often, referring to the
                  date of the last modification on the page.
                </p>
              </div>
            </section>
            {terms_and_condition != 100 && (
              <>
                <div className={styles.radiowrapper}>
                  <p>Kindly accept the terms and conditions to proceed</p>
                  <Checkbox className={styles.wrapperp} onChange={onChange}>
                    I accept the Payinvert Business terms and conditions
                  </Checkbox>
                </div>
                <div className={styles.buttonflex}>
                  <button
                    onClick={() => submitHandler(false)}
                    className={styles.cancel}
                  >
                    Decline
                  </button>

                  <button
                    disabled={!checked}
                    onClick={() => submitHandler(true)}
                    className={styles.submitHandler}
                  >
                    Accept
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
